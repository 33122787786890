<!-- Loading bar -->
<!-- <fuse-loading-bar ></fuse-loading-bar> -->

<!-- Navigation -->


<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation?.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 justify-center">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-26" src="assets/images/logo/ISPF_Logo_Long.png" />
                <img class="h-6" src="assets/images/logo/Siemens-Logo.png" />
            </div>
            <!-- Components -->
            <!-- <div class="flex items-center ml-auto">
                <notifications></notifications>
                <user [showAvatar]="false"></user>
            </div> -->
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img class="w-full h-full rounded-full" *ngIf="!user?.avatar" src="https://ui-avatars.com/api/?bold=true&color=049BD8&format=svg&size=512&rounded=true&name={{
                        user?.firstName + ' ' + user?.lastName
                    }}" alt="User avatar" />
                <mat-icon class="icon-size-24" *ngIf="user?.avatar" [svgIcon]="'heroicons_solid:user-circle'">
                </mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div *ngIf="user?.firstName"
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{ user?.firstName + " " + user?.lastName }}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{ user?.['countryCode'] }}{{user?.['phoneNumber']}}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <!-- <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </ng-container> -->
</fuse-vertical-navigation>

<!-- Wrapper -->

<div class=" flex flex-col flex-auto w-full min-w-0">

    <div *ngIf="buynowContainer">
        <div *ngIf="!state" [@expandCollapse]
            class="flex  animated-box w-full bg-[#29285F] py-2 px-4 md:hidden justify-center relative">
            <div class="flex items-center ">
                <span class="mr-4 text-white italic sm:text-md text-sm font-medium"> Want access to more TACtivities™?
                </span>
                <div matRipple (click)="goToShopify()"
                    class="w-20 cursor-pointer text-sm  italic rounded-2xl bg-primary outline outline-offset-2 outline-2 outline-primary text-white px-3 py-1 font-bold">
                    Buy Now
                </div>
            </div>
            <button (click)="bannerExpand()" class=" absolute top-0 right-0 z-10">
                <mat-icon class="  text-white" svgIcon="mat_outline:close">
                </mat-icon>
            </button>
        </div>
    </div>
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <div class="text-secondary sm:text-sm text-xs" *ngIf="checkRoute(router.url)">
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                institute || teacherInfo?.lastInstitutionName
                }}</span><span class="text-black">&nbsp;>&nbsp;</span>
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                classroom || teacherInfo?.lastClassroomName
                }}</span><span class="text-black">&nbsp;>&nbsp;</span>
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                programme || teacherInfo?.lastProgrammeName
                }}</span>
            <ng-container *ngIf="checkSubmissionRoute(router.url)">
                <span *ngIf="lu || teacherInfo?.lastLearningUnitName" class="text-black">&nbsp;>&nbsp;</span>
                <span *ngIf="lu || teacherInfo?.lastLearningUnitName" class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                    lu || teacherInfo?.lastLearningUnitName }}</span>
            </ng-container>
        </div>
        <div class="text-secondary sm:text-sm text-xs" *ngIf="checkSubmissionsRoute(router.url)">
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                institute || teacherInfo?.lastInstitutionName
                }}</span>
            <span class="text-black">&nbsp;>&nbsp;</span>
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                classroom || teacherInfo?.lastClassroomName
                }}</span>
            <span class="text-black">&nbsp;>&nbsp;</span>
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                programme || teacherInfo?.lastProgrammeName
                }}</span>
            <ng-container *ngIf="lu">
                <span class="text-black">&nbsp;>&nbsp;</span>
                <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                    lu
                    }}</span>
            </ng-container>
            <span class="text-black">&nbsp;>&nbsp;</span>
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{
                router.url.includes("/quiz-submissions")
                ? "Quiz Submissions"
                : "Upload Submissions"
                }}</span>
        </div>
        <div class="text-secondary sm:text-sm text-xs" *ngIf="router.url.includes('nomination-dashboard')">
            <span class="py-1 sm:px-3 rounded-full sm:bg-blue-50">{{institute}}</span>
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
            <notifications></notifications>
            <user [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto relative">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>

        <div *ngIf="sharedService.isWhatsappBtnVisible" class="mr-2"
            [ngClass]="isWorkFlowView?'waChat--container':'otherView'">
            <div id="header1" [ngClass]="isWorkFlowView?'showCrossbutton':'removeCrossbutton'">
                <button id="waChat--close-button" (click)="closeWhatsappChat()">
                    <mat-icon class="icon-size-4 absolute top-4 right-1 z-10" svgIcon="mat_outline:close">
                    </mat-icon>
                </button>
            </div>
            <a id="waChat--button" [href]="
                    'https://wa.me/917483945803?text=Greetings%21+I+have+a+question+regarding+Teacher+corner.'
                " target="_blank"></a>
            <span id="waChat--text">Need Help? Chat with us</span>
        </div>


        <!-- <div *ngIf="sharedService.isWhatsappBtnVisible"
            class="flex  animated-box w-max py-2 px-4  justify-center absolute" id="waChat--container">
            <div class="flex items-center ">

                <span id="waChat--text">Need Help? Chat with us</span>

                <div class="w-24 cursor-pointer text-sm  italic rounded-2xl bg-primary outline outline-offset-2 outline-2 outline-primary text-white px-3 py-1 font-bold
                flex items-center justify-center">
                    <a id="waChat--button" [href]="
                'https://wa.me/917483945803?text=Greetings%21+I+have+a+question+regarding+the+Raman+Award.'
            " target="_blank"></a>

                </div>
            </div>

            <button id="waChat--close-button" (click)="closeWhatsappChat()">
                <svg viewBox="0 0 24 18" fit="" class="absolute top-0 right-0" height="16" width="16"
                    preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z">
                    </path>
                </svg>
            </button>
        </div> -->

        <div *ngIf="buynowContainer">
            <div *ngIf="buynowOpen" [@zoomOut] class="absolute left-5 bottom-5 z-10 md:block hidden">
                <div class='flex'>
                    <span class=" mr-4 buynowtext">
                        Want access to more TACtivities™?
                    </span>
                    <div class="relative cursor-pointer" (click)="goToShopify()">
                        <div matRipple
                            class="w-30 buynowShadow italic rounded-2xl bg-primary outline outline-offset-2 outline-2 outline-primary text-white px-3 py-1 font-bold">
                            Buy Now
                        </div>
                        <div matRipple
                            class="h-11 w-11 bg-white border-2 border-primary-500 rounded-full absolute z-2 -right-1 -top-2 flex justify-center items-center shadow-xl">
                            <mat-icon class="text-primary" svgIcon="heroicons_outline:shopping-cart"></mat-icon>
                        </div>
                        <button (click)="buyNowCloseBtn()" matTooltip="Close the 'Buy Now' button"
                            class=" absolute -top-3 -right-1 z-10  hover:scale-125 transition duration-5000 transform">
                            <mat-icon class="icon-size-4 " svgIcon="mat_outline:close">
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->