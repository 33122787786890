import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject, first, lastValueFrom, take, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { SideNavigationService } from 'app/shared/navigation.service';
import { ProgrammeService } from 'app/core/dbOperations/programmes/programme.service';
import { InstitutionsService } from 'app/core/dbOperations/institutions/institutions.service';
import { ClassroomsService } from 'app/core/dbOperations/classrooms/classrooms.service';
import { UserService } from 'app/core/dbOperations/user/user.service';
import { LearningUnitsService } from 'app/core/dbOperations/learningUnits/learningUnits.service';
import { fuseAnimations } from '@fuse/animations';
import { ShopifyService } from 'app/shared/shopify.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutCartComponent } from 'app/modules/checkout-cart/checkout-cart.component';
import { UiService } from 'app/shared/ui.service';
import { TeacherService } from 'app/core/dbOperations/teachers/teachers.service';
import { SharedService } from 'app/shared/shared.service';
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations

})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    isWorkFlowView = false
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    institute: any;
    programme: any;
    classroom: any;
    lu: any;
    teacherInfo: any;
    state = false
    whatsappbuttonVisible = true
    buynowOpen = true
    classBs = new BehaviorSubject(null)
    buynowContainer: boolean = false;
    routingEvent: any;
    params: any;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public router: Router,
        private programmeService: ProgrammeService,
        private instituteService: InstitutionsService,
        private classroomService: ClassroomsService,
        private userService: UserService,

        private luService: LearningUnitsService,
        private shopifyService: ShopifyService,
        public dialog: MatDialog,
        private uiService: UiService,
        private teacherService: TeacherService,
        public sharedService: SharedService
    ) {
        this.checkUrlForInstituteId()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        this.params = await lastValueFrom(this._activatedRoute.queryParams.pipe(first()));
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });
        this.shopifyService.getAllActiveProducts().subscribe(res => res)
        this._navigationService.classroomNavSubject$.subscribe(res => {
            // res?.default.forEach(ob => {
            //     let a = ob.children?.sort((a, b) => parseFloat(a.title?.split(' ')[0]) - parseFloat(b.title?.split(' ')[0]));
            // })
            res?.default.forEach(ob => {
               let isStemClubExist= ob?.children[0]?.id=="stemclubs" || ob?.children[0]?.id=="classrooms"?true:false

               if(isStemClubExist){
                this.sortChildrenByTitle(ob)
               }
               else{
                ob?.children?.sort((a, b) => parseFloat(a?.title?.split(' ')[0]) - parseFloat(b?.title?.split(' ')[0]));
               }
            })
            this.navigation = res

        })


        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: any) => {
                this.user = user?.teacherMeta;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.instituteService.currentInstitutionName.subscribe(async res => {
            if (res !== null) {
                this.institute = res
            } else {
                this.institute = (await lastValueFrom(this.instituteService.getInstitutionByIdOnce(this.params.institutionId))).get('institutionName');
            };
        })
        this.programmeService.currentProgrammeName.subscribe(async res => {
            if (res !== null) {
                this.programme = res
            } else {
                this.programme = (await lastValueFrom(this.programmeService.getProgrammeDocByIdOnce(this.params.programmeId))).get('displayName');
            };
        })
        this.classroomService.currentClassroomName.subscribe(async res => {
            if (res !== null) {
                this.classroom = res
            }
            if (res === null || res === undefined) {
                const classroomData: any = (await lastValueFrom(this.classroomService.getClassroomByIdOnce(this.params.classroomId))).data();
                if (classroomData && classroomData.hasOwnProperty('type')) {
                    if (classroomData.type === 'STEM-CLUB') {
                        this.classroom = classroomData.stemClubName;
                    }
                    else if (classroomData?.type === 'CLASSROOM') {
                        this.classroom = classroomData.classroomName;
                    };
                };
                // else {
                //     console.error(`Classroom/Stem Club ${this.params.classroomId} does not have type`);
                // };
                // this.classroom = (await lastValueFrom(this.classroomService.getClassroomByIdOnce(this.params.classroomId))).get('classroomName');
            }
            if (res?.includes('2') || res?.includes('1')) {
                this.buynowContainer = false
            }
            this.classBs.next(res)
            if (!res) {
                // console.error('noRoute');
                this.getTeacherInfo()
            }
        })
        this.luService.currentLearningUnitsName.subscribe(async res => {
            if (res !== null) {
                this.lu = res
            } else {
                const luid = this._activatedRoute.firstChild.snapshot.params.tacDocId;
                this.lu = (await lastValueFrom(this.luService.getLUByIdOnce(luid))).get('learningUnitDisplayName');
            };
        })
        this.checkBuynowState()
        this.userService.changeWhatsappIconPosition.subscribe((d) => {
            this.isWorkFlowView = d
        })
    }

    // Function to split numeric and alphabetic parts of a title
    splitNumericAlpha = (title) => {
        const numericPart = title.match(/\d+/);
        const alphaPart = title.replace(/\d+/g, '').trim();
        return [numericPart ? parseInt(numericPart[0]) : null, alphaPart];
    };

    // Function to sort children by numeric and alphabetical order
    sortChildrenByTitle = (data) => {
        data.children.forEach(parent => {
            if (parent.id === "classrooms" || parent.id === "stemclubs") {
                parent.children.sort((a, b) => {
                    const [aNum, aAlpha] = this.splitNumericAlpha(a.title);
                    const [bNum, bAlpha] = this.splitNumericAlpha(b.title);

                    if (aNum !== null && bNum !== null) {
                        if (aNum !== bNum) {
                            return aNum - bNum;
                        }
                        return aAlpha.localeCompare(bAlpha);
                    } else if (aNum !== null) {
                        return -1;
                    } else if (bNum !== null) {
                        return 1;
                    } else {
                        return aAlpha.localeCompare(bAlpha);
                    }
                });
            }
        });
    };
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        // const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    async getTeacherInfo() {
        (await this.userService.getTeacherInfo()).pipe(take(1)).subscribe(res => {
            this.userService.userInfoSub.next(res)
            this.teacherInfo = res.currentTeacherInfo
            this.classBs.next(res?.lastClassroomName)
        }
        )
    }

    checkSubmissionRoute(value) {
        if (value.includes("/programme/")) {

            return true
        }
        else {
            return false
        }
    }

    checkRoute(value) {
        if (value.includes('/quiz-submissions')
            || value.includes('/upload-submissions')
            || value.includes('/programmes')
            || value.includes('/manage')
            || value.includes('/learning-units')
            || value.includes('/quizzer')
            || value.includes('/assignments')
            || value.includes('/classrooms')
            || value.includes('/stem-clubs')
            || value.includes('/contests')
            || value.includes('/institutions-list')
            || value.includes('/nomination-dashboard')
            || value.includes('/events')
            || value.includes('/programme-templates')
        ) {

            return false
        }
        else {
            return true
        }
    }

    checkSubmissionsRoute(value) {
        if (value.includes("/quiz-submissions") || value.includes('/upload-submissions')) {
            return true
        }
        else {
            return false
        }
    }

    bannerExpand() {
        this.state = true
    }

    buyNowCloseBtn() {
        this.buynowOpen = false
        localStorage.setItem('isBuynowDivClose', 'true')
    }

    checkBuynowState() {
        const value = localStorage.getItem('isBuynowDivClose')
        if (value == 'true') {
            this.buynowOpen = false
        }


    }

    goToShopify() {
        this.classBs.pipe(take(1)).subscribe(async res => {
            let cls = res[0];


            this.shopifyService.getAllActiveProducts().pipe(take(1)).subscribe(async (res: any) => {
                let product = res.products.filter(pr => {
                    return pr.handle.includes(`-${this.convertToRoman(cls)}-`)
                })

                if (product) {
                    await import('app/modules/checkout-cart/checkout-cart.module').then(() => {
                        this.dialog.open(CheckoutCartComponent, {
                            data: product[0],
                            panelClass: 'custom-dialog-container'
                        });

                    })
                }
                else {
                    this.uiService.alertMessage('NO Product', 'no product available for this classroom', 'error')
                }
                // if (product) {
                //     const url = `https://www.thinktac.com/products/${product[0].handle}`
                //     var a = document.createElement('a')
                //     a.href = url
                //     a.target = '_blank'
                //     a.click()
                // }
            })
        })



    }

    convertToRoman(num) {
        var roman = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            xl: 40,
            x: 10,
            ix: 9,
            v: 5,
            iv: 4,
            i: 1
        };
        var str = '';

        for (var i of Object.keys(roman)) {
            var q = Math.floor(num / roman[i]);
            num -= q * roman[i];
            str += i.repeat(q);
        }

        return str;
    }

    checkUrlForInstituteId() {
        if (this.router.url.includes('AydoKWQPcmUkw4127sm9')) {
            if (
                this.router.url.includes('cSsjdCnYIHo54kyWSgNt') ||
                this.router.url.includes('OLuIzWPHi1TjsXKbEZEn') ||
                this.router.url.includes('VvurWF0sWmDxYK6zfmDt') ||
                this.router.url.includes('kkmVlFzNhOumtPIjb82p') ||
                this.router.url.includes('GW7iC4I94E5cdCl8eJl6') ||
                this.router.url.includes('p8zOxrohRzTfvr1E7B4E') ||
                this.router.url.includes('qOCOxMMLtLqM74BgelJj') ||
                this.router.url.includes('nHEQCZZzI4zSI5agv6D8') ||
                this.router.url.includes('dashboard/programme')


            ) {
                this.buynowContainer = false
            }
            else {
                this.buynowContainer = true
            }
        }

        this.routingEvent = this.router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationEnd) {

                    if (event.url.includes('AydoKWQPcmUkw4127sm9')) {
                        this.buynowContainer = true
                        if (
                            event.url.includes('cSsjdCnYIHo54kyWSgNt') ||
                            event.url.includes('OLuIzWPHi1TjsXKbEZEn') ||
                            event.url.includes('VvurWF0sWmDxYK6zfmDt') ||
                            event.url.includes('kkmVlFzNhOumtPIjb82p') ||
                            event.url.includes('GW7iC4I94E5cdCl8eJl6') ||
                            event.url.includes('p8zOxrohRzTfvr1E7B4E') ||
                            event.url.includes('qOCOxMMLtLqM74BgelJj') ||
                            event.url.includes('nHEQCZZzI4zSI5agv6D8') ||
                            event.url.includes('dashboard/programme')

                        ) {
                            this.buynowContainer = false
                        }
                        else {
                            this.buynowContainer = true
                        }
                    }

                    else {
                        this.buynowContainer = false
                    }
                }
            }
        )
    }

    closeWhatsappChat() {
        // this.whatsappbuttonVisible=false
        this.sharedService.isWhatsappBtnVisible = false
    }

}
