import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, QueryFn } from '@angular/fire/compat/firestore';
import { User } from 'app/core/dbOperations/user/user.types';
import { BehaviorSubject, first, lastValueFrom, map, Observable, of, pipe, ReplaySubject, take, tap, timer } from 'rxjs';
import { AssignmentsFirestore } from './assignments.firestore';
import { serverTimestamp } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class AssignmentsService {
    private _assignments: ReplaySubject<User> = new ReplaySubject<User>(1);
    currentassignment = new ReplaySubject<User>(1)
    currentassignmentId = new BehaviorSubject(null);
    getAllLiveAssignmentsSub = new BehaviorSubject(null);
    assignmentList = new BehaviorSubject(null);
    assignmentsSub = new BehaviorSubject(null);
    allAssignmentSelectedClassroomSub = new BehaviorSubject(null)
    allAssignmentSel = new BehaviorSubject(null)
    existingAssignment: any;

    // authUser: aUser.User
    /**
     * Constructor
     */
    constructor(
        private assignmentFirestore: AssignmentsFirestore,
        public afAuth: AngularFireAuth,
        protected afs: AngularFirestore,
        private http: HttpClient,
    ) {
    }

    getAllLiveAssignments() {
        const query: QueryFn = (ref: CollectionReference) => ref.where('status', '==', 'LIVE');
        return this.assignmentFirestore.collection$(query).pipe(take(1), map((assignments: any) => assignments.map(d => ({ displayName: d.displayName, docId: d.docId, type: d.type }))),
            tap((assignments) => {
                return this.getAllLiveAssignmentsSub.next(assignments)
            }))
        // return this.assignmentFirestore.collection$(query).pipe(map((assignments: any) => assignments.map(d => ({ displayName: d.displayName, docId: d.docId, type: d.type }))))
    }

    async getAssignmentNameWithType(assignmentIdsArr, type) {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('docId', 'in', assignmentIdsArr)
                .where('type', '==', type)
        return this.assignmentFirestore.collection$(query);/*.pipe(take(1) ,
            map(async (assignments: any) => {
                if (assignments.length) {
                    console.log(await assignments);

                    return await assignments.map(async d => await d.displayName)
                }
                else return false
            }) )*/
    }
    subscriptionArr = []

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set assignments(value: User) {
        // Store the value
        this._assignments.next(value);
    }

    get assignments$(): Observable<User> {
        // this._assignments.subscribe(a => {
        //     console.log(a);
        // })

        return this._assignments.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in assignments data
     */
    async get(): Promise<Observable<any>> {
        const usr: any = await this.afAuth.authState.pipe(first()).toPromise();
        const query: QueryFn = (ref: CollectionReference) => ref.where('uid', '==', usr.uid)
        // const query: QueryFn = (ref: CollectionReference) => ref.where('uid', '==', 'fqawdKwpdTeUbyE5HJYPRroCypz2')

        return this.assignmentFirestore.collection$(query).pipe(take(1),
            tap((assignments) => {
                return this._assignments.next(assignments);
            }))
    }

    getResources(id, studentObj) {
        return this.afs.doc(`Students/${id}/Submissions/${studentObj.classroomId}-${studentObj.programmeId}`).get()
    }

    create(value): Promise<any> {
        return this.assignmentFirestore.create(value)
    }

    createWithId(value, id): Promise<any> {
        return this.assignmentFirestore.createWithId(value, id)
    }

    update(value, id): Promise<any> {
        return this.assignmentFirestore.update(value, id)
    }

    delete(id: string) {
        return this.assignmentFirestore.delete(id);
    }

    updateInSubmission(value, studentId, classId) {
        this.afs.doc(`Students/${studentId}/submissions/${classId}`).set(value, { merge: true })
    }

    getWithId(id) {
        return this.assignmentFirestore.doc$(id).pipe(take(1),
            tap((assignment) => {
                return this.currentassignment.next(assignment);
            }))
    }

    getAssignmentByIdOnce(id) {
        return this.assignmentFirestore.getWithGet(id);
    }

    getClassroomLists(id) {
        return this.assignmentFirestore.doc$(id).pipe(take(1))
    }

    getAssignments(tacCode, version) {
        const today = Math.floor(Date.now() / 1000);

        const query: QueryFn = (ref: CollectionReference) => ref
            .where('DripDate._seconds', '<=', today)
            .where('TAC_Code', '==', tacCode)
            .where('Version', '==', version)
            .orderBy('DripDate._seconds', 'desc')

        return this.assignmentFirestore.collection$(query).pipe(take(1))
    }

    /**
     * Update the assignments
     *
     * @param assignments
     */
    // update(assignments: User): Observable<any> {
    //     return this._httpClient.patch<User>('api/common/assignments', { assignments }).pipe(
    //         map((response) => {
    //             this._assignments.next(response);
    //         })
    //     );
    // }

    getRandomGeneratedId() {
        return this.assignmentFirestore.getRandomGeneratedId()
    }

    getAllQuiz() {
        const query: QueryFn = (ref: CollectionReference) => ref.where('type', '==', 'QUIZ')
        return this.assignmentFirestore.collection$(query)
    }

    getAllAssignments() {
        const query: QueryFn = (ref: CollectionReference) => ref.where('docId', 'not-in', ['---quizzer_schema---', '--default_assignments--'])
        return this.assignmentFirestore.collection$(query)
    }

    getTeachersResources(TeacherObj) {
        return this.afs.doc(`Teachers/${TeacherObj.docId}/submissions/${TeacherObj.classroomId}-${TeacherObj.programmeId}`).get()
    }

    updateTeachersSubmission(value, TeachersObj) {
        this.afs.doc(`Teachers/${TeachersObj.docId}/submissions/${TeachersObj.classroomId}-${TeachersObj.programmeId}`).set(value, { merge: true })
    }

    updateEventSubmission(eventId, teacherId, value) {
        return this.afs.doc(`Teachers/${teacherId}/submissions/${teacherId}-${eventId}`).set(value, { merge: true })
    }

    getEventSubmission(eventId, teacherId) {
        return this.afs.doc(`Teachers/${teacherId}/submissions/${teacherId}-${eventId}`).get()
    }

    trashCollection() {
        return this.afs.collection('Assignments').doc('--trash--').collection('DeletedAssignments').valueChanges()
    }

    deleteInTrash(docId) {
        return this.afs.collection('Assignments').doc('--trash--').collection('DeletedAssignments').doc(docId).delete()
    }

    toTrash(docId, classInfo) {
        this.afs.collection('Assignments').doc('--trash--').collection('DeletedAssignments').doc(docId).set({ ...classInfo, trashAt: serverTimestamp() })
    }

}
