import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ConfigurationsFirestore } from './configuration.firestore';
import { first, lastValueFrom, map } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, take } from 'rxjs';
import { TeacherService } from '../teachers/teachers.service';
import { UserService } from '../user/user.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(
    private configureFirestore: ConfigurationsFirestore,
    public afAuth: AngularFireAuth,
    private userService: UserService,
    private afs: AngularFirestore,
    private teacherService: TeacherService,
  ) { }
  public boardListSub = new BehaviorSubject(null)
  public chainSub = new BehaviorSubject(null)
  public subjectsSub = new BehaviorSubject(null)
  public languageListSub = new BehaviorSubject(null)
  public institutionTypesListSub = new BehaviorSubject(null)
  public defaultsProgrammes = new BehaviorSubject(null)

  async getProgrammeByBGS(grade) {
    const defaultProg = await this.configureFirestore.getDocDataByDocId('programmes')
    const programme = defaultProg['defaults']['defaultProgramme']?.[`${grade}-Science`]
    // const programme = defaultProg['defaults']?.[boardName]?.[`${grade}-${subject}`] || defaultProg['defaults']['defaultProgramme']
    return programme
  }

  getAllConfigure() {
    this.getBoardList('BoardListAll')
    this.getLanguageList('Languages')
    this.getInstituteTypesList('InstitutionTypes')
  }

  getInstitutesChainInfo() {
    return this.configureFirestore.doc$('InstitutionChains').pipe(take(1)).subscribe(d => {
      this.chainSub.next(d.chainsInfo)
    })
  }

  setInstitutesChainInfo(value) {
    return this.configureFirestore.update(value, 'InstitutionChains')
  }

  getBoardList(id) {
    return this.configureFirestore.doc$(id).pipe(take(1)).subscribe(d => {
      this.boardListSub.next(d.boards)
    })
  }

  getLanguageList(id) {
    return this.configureFirestore.doc$(id).pipe(take(1)).subscribe(d => {
      this.languageListSub.next(d.langTypes)
    })
  }

  getInstituteTypesList(id) {
    return this.configureFirestore.doc$(id).pipe(take(1)).subscribe(d => {
      this.institutionTypesListSub.next(d.InstitutionTypes)
    })
  }

  async getLanguageListForProgrammes() {
    return this.afs.doc('Configuration/Languages').get().toPromise().then((d: any) => d.data().langTypes)
  }

  getSubjects() {
    return this.configureFirestore.doc$('subjects').pipe(take(1)).subscribe(d => {
      this.subjectsSub.next(d.subjectsNames)
    })
  }

  getDoc(id) {
    return this.configureFirestore.doc$(id).pipe(take(1)).subscribe(d => {
      return this.subjectsSub.next(d)
    })
  }

  async storedTeacherInfo() {
    const a = (await this.userService.get()).toPromise()
    const usrInfo = await a || {}
    this.userService.storedUserInfo.next(usrInfo.currentTeacherInfo)
    this.teacherService.getWithId(usrInfo.docId).pipe(take(1)).subscribe(res => {
      // this.teacherService.updateUser()
    })
  }

  getResourceNames() {
    return this.configureFirestore.doc$('ResourceNames');
  }

  async getEmailUpdateTemplate() {
    return await this.configureFirestore.getDocDataByDocId('email_otp_for_profile_update_teachersCorner');
  }

  AddNotifications(templateChnage, emailTemplate, email) {
    this.afs.collection('EmailNotifications').add({
      message: {
        // html: this.mailTemplate['html'],
        html: templateChnage,
        subject: emailTemplate['subject'],
      },
      to: email,
    }).catch(err => {
      console.error(err);
    });

  }

  getRYSICategories() {
    return this.configureFirestore.doc$('RYSI_Categories');
  }

  getCounters() {
    return this.configureFirestore.doc$('Counters')
  }

  incrementProgrammeCodeCounter(programeCodeLatestValue) {
    const value = { programmeCode: programeCodeLatestValue }
    return this.configureFirestore.update(value, 'Counters')
  }

  getProgrammes() {
    return this.configureFirestore.doc$('programmes')
  }

  getProgrammesWithWorkflow() {
    return this.configureFirestore.doc$('programmes_with_default_workflow')
  }

  getClassrooms() {
    return this.configureFirestore.doc$('classrooms')
  }

  getRandomDocId() {
    return this.afs.createId()
  }

  getTeacherCornerConfigurations() {
    return this.configureFirestore.doc$('teacher_corner_configurations');
  }

  getCountryCodesList() {
    return this.configureFirestore.doc$('CountryCodes');
  }

  getInternationalBoardList() {
    return this.configureFirestore.doc$('BoardListAll');
  }

  updateInternationalBoardList(updatedBoard: any) {
    this.configureFirestore.update(updatedBoard, 'BoardListAll');
  }

  createAcronym(objectName: string) {
    // Define a function to create a unique acronym
    let boardName: any;
    let boardLocation: any;
    let location: any;
    if (objectName.includes(',')) {
      boardName = objectName.split(',')[0].split(/\s/);
      boardLocation = objectName.split(',')[1].trim().split(/\s/);
      location = boardLocation.length > 1 ? boardLocation.map(word => word[0].toUpperCase()) : boardLocation[0].toUpperCase();
    } else {
      boardName = objectName.split(/\s/);
    }
    const allFirstCaps = boardName.map(word => word[0].toUpperCase());
    const acronym = allFirstCaps.concat(location).join('');
    return acronym;
  }

  addNewBoardToConfig(isAddNewBoard: boolean, board: string, boardData: any, countryBoard: Array<any>, country: string) {
    if (isAddNewBoard) {
      const newBoard = {
        "code": this.createAcronym(board),
        "name": board
      };
      const boardDoc = boardData;
      const updatedCountryBoard = countryBoard.concat(newBoard);
      boardDoc.boardsInternational[country.toLowerCase()] = updatedCountryBoard;
      this.updateInternationalBoardList(boardDoc);
    }
  }

  getCountryNameFromCode(countryCode: string) {
    return this.configureFirestore.doc$('CountryCodes')
      .pipe(
        map(res => {
          const index = Object.values(res?.countryCodes).map((item: any) => item?.phone).indexOf(countryCode);
          return Object.keys(res?.countryCodes).at(index);
        })
      )
  }

  getCountryCodeFromPhone(phone: string) {
    const phoneWithoutCode = phone?.slice(-10);
    return phone.split(phoneWithoutCode)[0];
  }

  async getInternationalBoards(currentUser: any, formName: FormGroup, isLoaded: boolean, parentData?: any) {
    // get country codes list
    // const configCodes = this.getTeacherCornerConfigurations().pipe(first());
    const configCodes = this.getCountryCodesList().pipe(first());
    const countryCodeList = await lastValueFrom(configCodes);
    const countryCodes = countryCodeList?.countryCodes;
    // get teacher's phone country code
    let countryCode: string;
    if (parentData && parentData?.parent !== 'institutions-list') {
      countryCode = countryCodes?.[parentData?.country]?.phone;
    } else {
      countryCode = currentUser?.countryCode;
    };
    // get international boards list
    const configBoards = this.getInternationalBoardList().pipe(first());
    const boardData = await lastValueFrom(configBoards)
    // set default country name from teacher's phone country code
    let countryName: string;
    if (parentData && parentData?.country) {
      countryName = parentData?.country?.includes(' ') ? parentData?.country?.toLowerCase()?.replace(/\s/g, '-') : parentData?.country?.toLowerCase();
    } else {
      const fromCountryCode = this.getCountryNameFromCode(countryCode).pipe(first());
      countryName = await lastValueFrom(fromCountryCode);
    }
    if (parentData?.parent && parentData?.parent !== 'institutions-list') {
      if (formName.contains('institutionAddress')) {
        formName?.get('institutionAddress.country')?.setValue(parentData?.country);
      } else if (formName.contains('country')) {
        formName.get('country')?.setValue(parentData?.country);
      }
    } else {
      if (formName?.controls?.hasOwnProperty('institutionAddress')) {
        formName?.get('institutionAddress.country')?.setValue(countryName);
      } else {
        formName?.get('country')?.setValue(countryName);
      }
    };
    isLoaded = true;
    return { countryCode, countryCodes, boardData, countryName, isLoaded };
  }

  async saveNewBoard(infoForm: FormGroup, isAddNewBoard: boolean, boardData: any, countryBoard: any, teacherCountry: string) {
    // save new board to database
    const boardValue = infoForm.get('board').value.toString();
    this.addNewBoardToConfig(isAddNewBoard, boardValue, boardData, countryBoard, teacherCountry);
    // get country boards list
    const configBoards = this.getInternationalBoardList().pipe(first());
    const boards = await lastValueFrom(configBoards);
    const internationalBoards = boards?.boardsInternational;
    countryBoard = internationalBoards?.[teacherCountry.toLowerCase()];
    // disable text field and set value to new board
    // this.infoForm.get('board').reset();
    isAddNewBoard = false;
    infoForm.patchValue({ board: this.createAcronym(boardValue) });
    return { boards, countryBoard, isAddNewBoard };
  }

  async saveNewSubject(infoForm: FormGroup, isAddNewSubject: boolean, subjectList: Array<any>) {
    // save new subject to database
    const subjectValue = infoForm.get('subject').value.toString();
    const updatedSubjectList = await this.addNewDataToTemplateConfig(isAddNewSubject, subjectList, subjectValue, 'subjectList');
    // disable text field and set value to new board
    // this.infoForm.get('subject').reset();
    // reset the add new subject
    isAddNewSubject = false;
    infoForm.get('subject').patchValue(subjectValue);
    return { updatedSubjectList, isAddNewSubject };
  }

  async saveNewTemplateCategory(infoForm: FormGroup, isAddNewCategory: boolean, templateCategoryList: Array<any>) {
    // save new template category to database
    const templateValue = infoForm.get('templateCategory').value.toString();
    const updatedCategoryList = await this.addNewDataToTemplateConfig(isAddNewCategory, templateCategoryList, templateValue, 'templateList');
    // disable text field and set value to new board
    // this.infoForm.get('templateCategory').reset();
    // reset the add new category
    isAddNewCategory = false;
    infoForm.get('templateCategory').patchValue(templateValue);
    return { updatedCategoryList, isAddNewCategory };
  }

  addNewDataToTemplateConfig(isAddNewData: boolean, dataList: Array<any>, newData: any, listName: string) {
    if (isAddNewData) {
      const updatedData = [...dataList, newData];
      this.updateProgrammeTemplateObject(listName, updatedData);
      return updatedData;
    }
  }

  getMaxOtpAttempts() {
    return this.configureFirestore.getDocDataByDocId('otpAttemptsByusers')
  }

  getDescriptionSizelimit() {
    return this.configureFirestore.doc$('LUDescriptionwordlimit');
  }

  getLearningUnitTypes() {
    return this.configureFirestore.doc$('LearningUnitTypes');
  }

  addLUtype(value) {
    this.configureFirestore.update(value, 'LearningUnitTypes')
  }

  getTypeOfInstitutionsByGet() {
    return this.configureFirestore.getWithGet('InstitutionTypes')
  }

  getProgrammeTemplateObject() {
    return this.configureFirestore.doc$('programme_template_object');
  }

  updateProgrammeTemplateObject(listName: string, updatedList: any) {
    this.configureFirestore.update({ [listName]: updatedList }, 'programme_template_object');
  }

  getTeacherConfig() {
    return this.configureFirestore.doc$('teacher_config')
  }

}
