import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { FormatTimeConditionalPipe } from './pipes/format-time-conditional.pipe';
import { StandardQuillTextInputFieldComponent } from './components/standard-quill-text-input-field/standard-quill-text-input-field.component';
import { QuillModule } from 'ngx-quill';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

@NgModule({
    declarations: [
        FormatTimePipe,
        FormatTimeConditionalPipe,
        StandardQuillTextInputFieldComponent,
        StarRatingComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        QuillModule.forRoot({
            customOptions: [
                {
                    import: 'formats/font',
                    whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace']
                }
            ]
        }),
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormatTimePipe,
        FormatTimeConditionalPipe,
        StandardQuillTextInputFieldComponent,
        StarRatingComponent,
    ]
})
export class SharedModule {
}
