import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { FuseDrawerService } from 'app/core/dbOperations/fuseDrawerService/fuse-drawer.service';
import { UserService } from 'app/core/dbOperations/user/user.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { Subject, takeUntil } from 'rxjs';
import { RejectDialogComponent } from './reject-dialog/reject-dialog.component';
import { UiService } from 'app/shared/ui.service';

@Component({
  selector: 'app-self-registration-user-approval',
  templateUrl: './self-registration-user-approval.component.html',
  styleUrls: ['./self-registration-user-approval.component.scss']
})
export class SelfRegistrationUserApprovalComponent implements OnInit {
  viewAllRequest = true
  allpendingNotification: Notification[] = [];
  allNotificationActionsTaken: Notification[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user
  constructor(
    private drawerService: FuseDrawerService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    private uiService: UiService,
    private userService: UserService,
    private notificationService: NotificationsService) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    // Subscribe to notification changes
    this.notificationService.allApprovalRequestNotificationSub
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        // Load the notifications
        this.allpendingNotification = notifications.filter(message => message['actionTakenBy'] == '');
        this.allNotificationActionsTaken = notifications.filter(message => message['actionTakenBy'] != '');
      });
    this.userService.user$
      .pipe((takeUntil(this._unsubscribeAll)))
      .subscribe((user: any) => {
        this.user = user?.teacherMeta;
      });
  }
  toggleList(change: MatSlideToggleChange): void {
    this.viewAllRequest = !change.checked
  }

  async approveRequest(notificationObj, index) {
    const selfRegUserId = notificationObj?.selfRegUserId || '';
    const approvalObj = {
      selfRegTeacherApproval: {
        [`${notificationObj.classroomId}`]: {
          approvalStatus: true,
          classroomId: `${notificationObj.classroomId}`
        }
      }
    }
    if (selfRegUserId) {
      this.allpendingNotification.splice(index, 1)
      this.userService.updateSelfRegUserApproval(approvalObj, selfRegUserId);
      notificationObj.read = true;
      notificationObj.remove = true;
      notificationObj.actionTakenBy = `${this.user?.firstName || ''} ${this.user?.lastName || ''}`
      notificationObj.actionDate = new Date()
      this.allNotificationActionsTaken.push(notificationObj)
      this.notificationService.delete(notificationObj.id, notificationObj).subscribe();
      this.uiService.alertMessage('Successful', 'Approved', 'success');
    }
  }

  async updateIntoDB(notificationObj, index) {
    const selfRegUserId = notificationObj?.selfRegUserId || '';
    const approvalObj = {
      selfRegTeacherApproval: {
        [`${notificationObj.classroomId}`]: {
          approvalStatus: false,
          updatedAt: new Date,
          classroomId: `${notificationObj.classroomId}`
        }
      }
    }
    if (selfRegUserId) {
      this.allpendingNotification.splice(index, 1)
      this.userService.updateSelfRegUserApproval(approvalObj, selfRegUserId);
      notificationObj.read = true;
      notificationObj.remove = true;
      notificationObj.actionTakenBy = `${this.user?.firstName || ''} ${this.user?.lastName || ''}`
      notificationObj.actionDate = new Date()
      this.allNotificationActionsTaken.push(notificationObj)
      this.notificationService.delete(notificationObj.id, notificationObj).subscribe();
      this.uiService.alertMessage('Successful', 'Rejected', 'success');
    }
  }

  async rejectRequest(userNotification, i) {
    await import('./reject-dialog/reject-dialog.module').then(async () => {
      const dialogRef = this.dialog.open(RejectDialogComponent, {
        data: { ...userNotification },
      });
      dialogRef.afterClosed().subscribe((dialogCloseEvent: any) => {
        if (dialogCloseEvent?.eventName == 'save') {
          userNotification.rejectionReason = dialogCloseEvent?.message;
          this.updateIntoDB(userNotification, i);
          console.log(dialogCloseEvent.message);
        }
      })
    })
  }

  drawerClose() {
    this.drawerService.drawerOpenNotificationSubject.next(false)
  }
}
