import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssignmentsService } from 'app/core/dbOperations/assignments/assignments.service';
import { ClassroomsService } from 'app/core/dbOperations/classrooms/classrooms.service';
import { TeacherService } from 'app/core/dbOperations/teachers/teachers.service';
import { UserService } from 'app/core/dbOperations/user/user.service';
import { WorkflowsService } from 'app/core/dbOperations/workflows/workflows.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { Observable, ReplaySubject, firstValueFrom, switchMap, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    private _allRemoveNotification = [];

    allApprovalRequestNotificationSub: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    assignmentsNotificationsSub: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private assignmentService: AssignmentsService,
        private teacherService: TeacherService,
        private classroomService: ClassroomsService,
        private workflowService: WorkflowsService,
        private userService: UserService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */

    getDateYYYYMMDD(timeStamp = new Date()) {
        const today = timeStamp
        const year = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let date;
        let month;
        if (dd < 10) {
            date = '0' + dd;
        } else date = dd;
        if (mm < 10) {
            month = '0' + mm;
        } else month = mm;
        return Number(year + month + date);
    }

    filterAssignmentDueDate(assignmentRemainder) {
        const todayDate = this.getDateYYYYMMDD();
        const remainderNotifications = assignmentRemainder?.map(data => {
            const checkDueDate = this.getDateYYYYMMDD(new Date(data?.assignmentDueDate?.seconds * 1000))
            if ((todayDate + 1) >= checkDueDate && checkDueDate >= (todayDate)) {
                return ({
                    remainingDays: 1,
                    title: ' Assignment Due Date Approaching in 24 Hours',
                    description: ` Your assignment ${data.assignmentName} is due in less than 24 hours`,
                    icon: "mat_outline:circle_notifications",
                    id: data.assignmentId,
                    read: false,
                    remove: data?.remove || false,
                    time: data.assignmentDueDate,
                    updatedAt: '',
                    assignmentId: data.assignmentId,
                    learningUnitId: data.learningUnitId,
                    classroomId: data.classroomId,
                    // link: `/dashboard/programme/${data.learningUnitId}/${data.assignmentId}/?institutionId=${data.institutionId}&classroomId=${data.classroomId}&programmeId=${data.programmeId}&studentId=${data.studentId}`
                })
            }
        })
        return remainderNotifications?.filter(data => data != undefined).sort(function (a, b) { return a.time - b.time });
    }
    async getAll() {
        // console.log('resolve')
        return this.userService.userInfoSub.subscribe(async (userInfo: any) => {
            this.checkAssignmentsDueDate();
            if (userInfo?.accessLevel == 10) {
                return (await this.userService.getAllUsersNotifications()).subscribe(usersArr => {
                    const notificationArr = usersArr.flatMap(user => user?.teacherNotifications).filter(data => data != undefined)
                    return this.filterNotifications(notificationArr)
                })
            } else {
                return this.filterNotifications(userInfo?.teacherNotifications)
            }
        })
    }
    checkAssignmentsDueDate() {
        /* Check Classroom-Assignments */
        this.teacherService.associatedClassroom.subscribe(async teacherCLs => {
            if(teacherCLs) {
                const classIdArr = Object.keys(teacherCLs);
                // console.log(classIdArr);
                let clsArr = []
                classIdArr.map(clsId => clsArr.push(this.classroomService.getWorkflowAssignments(clsId)));
                const classRoomsArr = await (await Promise.all(clsArr).then(async clsData => await clsData)).filter(doc => doc != undefined)
                // console.log(classRoomsArr);

                const workflowIds = classRoomsArr?.length ? classRoomsArr?.flatMap(cls => (Object.keys(cls?.programmes)).flatMap(d => cls?.programmes[d]?.['workflowIds'])?.filter(wfId => wfId != undefined).map(w => ({ ...w, classroomId: cls?.classroomId }))) : []
                // console.log(workflowIds);

                let workflowIdsDocRefArr = []
                workflowIds.map(wf => workflowIdsDocRefArr.push(this.workflowService.getWorkflowDoc(wf['workflowId'])))

                const workFlowInfoArr: any = await Promise.all(workflowIdsDocRefArr).then(async wfData => await wfData);
                // const wfAssignmentsFilter = workFlowInfoArr.flatMap(wfSteps => wfSteps.workflowSteps.flatMap())
                // console.log(workFlowInfoArr);

                let allClsAssignments = []
                workFlowInfoArr?.map(workflowInfo => {
                    workflowInfo?.workflowSteps?.map(wfStep => {
                        wfStep.contents.map(content => {
                            if (content.contentType == 'assignment') {
                                let index = workflowIds.findIndex(d => d['workflowId'] == workflowInfo.workflowId)
                                let clsLUObj = workflowIds[index]
                                allClsAssignments.push({
                                    ...content,
                                    workflowId: workflowInfo.workflowId,
                                    ...clsLUObj
                                })
                            }
                        })
                    })

                })
                const filterAssignmentsData = this.filterAssignmentDueDate(allClsAssignments);
                // console.log(allClsAssignments);
                // console.log(filterAssignmentsData);

                /*  */
                const userDocRef = await this.userService.getTeacherInfo();
                const userDocData = await firstValueFrom(userDocRef);
                const userNotifications = userDocData?.teacherClassroomsNotifications || [];
                /*  */

                for (let dueDateAssignment of filterAssignmentsData) {
                    const checkObject = userNotifications.findIndex(data => data.classroomId == dueDateAssignment.classroomId && data.learningUnitId == dueDateAssignment.learningUnitId);
                    // console.log(checkObject);

                    if (checkObject < 0) {
                        userNotifications.push(dueDateAssignment)
                        // const doc= this.filterNotifications(dueDateAssignment)
                    }
                }
                this.assignmentsNotificationsSub.next(userNotifications || [])
            }

            })
        // this.assignmentService.allAssignmentSelectedClassroomSub.subscribe((allAssignments: any) => {
        //     const filterAssignmentsData = this.filterAssignmentDueDate(allAssignments);
        //     this.assignmentsNotificationsSub.next(filterAssignmentsData || [])
        // })
    }

    getFilterAssignments(assignments = []) {
        const assignmentData = assignments?.map(data => {
            return ({
                title: data.assignmentName,
                description: data.contentName,
                icon: "mat_outline:circle_notifications",
                id: data.docId,
                read: false,
                remove: false,
                time: data.assignmentDueDate,
                updatedAt: ''
            })
        });
        return assignmentData || []
    }
    filterNotifications(notificationArr) {
        const userNotificationArr = this.parseNotifications(notificationArr);
        this._allRemoveNotification = userNotificationArr.filter(msg => msg['remove'] == true);
        const allWithoutRemoveNotification = userNotificationArr.filter(msg => !(msg['remove'] == true))
        const allApprovalRequestNotification = userNotificationArr.filter(msg => (msg['approvalRequest'] == true))
        this._notifications.next(allWithoutRemoveNotification);
        this.allApprovalRequestNotificationSub.next(allApprovalRequestNotification)
    }

    parseNotifications(teacherNotifications) {
        return (teacherNotifications || []).map(data => ({
            id: data?.id || '',
            icon: data?.icon || '',
            image: data?.image || '',
            title: data?.title || '',
            description: data?.description || '',
            time: data?.time || '',
            link: data?.link || '',
            read: data?.read || false,
            remove: data?.remove || false,
            approvalRequest: data?.approvalRequest || false,
            selfRegUserId: data?.selfRegUserId || '',
            classroomId: data?.classroomId || '',

            firstName: data?.firstName || '',
            lastName: data?.lastName || '',
            countryCode: data?.countryCode || '',
            phoneNumber: data?.phoneNumber || '',
            email: data?.email || '',
            instituteName: data?.instituteName || '',
            classroomName: data?.classroomName || '',
            subject: data?.subject || '',
            actionTakenBy: data?.actionTakenBy || '',
            actionDate: data?.actionDate || '',
            rejectionReason: data?.rejectionReason || '',
            schoolRepUid: data?.schoolRepUid || '',
        }))
    }
    /**
     * Create a notification
     *
     * @param notification
     */

    /* Without Approval */
    addNewNotification(newNotification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => {
                /* Update into DB */
                let teacherNotificationsArr = {
                    teacherNotifications: [...this._allRemoveNotification, ...notifications, newNotification]
                }

                if (teacherNotificationsArr?.teacherNotifications)
                    this.userService.setTeacherInfo(teacherNotificationsArr);

                this._notifications.next(notifications);
                return notifications
            })
        );
    }
    // create(notification: Notification): Observable<Notification> {
    //     return this.notifications$.pipe(
    //         take(1),
    //         switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
    //             map((newNotification) => {

    //                 // Update the notifications with the new notification
    //                 this._notifications.next([...notifications, newNotification]);

    //                 // Return the new notification from observable
    //                 return newNotification;
    //             })
    //         ))
    //     );
    // }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, updatedNotification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => {
                // Find the index of the updated notification
                const index = notifications.findIndex(item => item.id === id);
                // Update the notification
                notifications[index] = updatedNotification;
                /* Update into DB */


                let teacherNotificationsArr = {
                    teacherNotifications: [...this._allRemoveNotification, ...notifications].filter(msg => msg?.['approvalRequest'] != true)
                }
                if (teacherNotificationsArr?.teacherNotifications) {
                    this.userService.setTeacherInfo(teacherNotificationsArr);
                }



                // console.log({ this._allRemoveNotification })
                // console.log({ notifications });
                // console.log({ teacherNotificationsArr });



                this._notifications.next(notifications);
                return notifications
            })
        );
    }

    /**
     * Delete approval notification
     *
     * @param id
     */
    delete(id: string, updatedNotification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => {
                // Find the index of the updated notification
                const index = notifications.findIndex(item => item.id === id);
                // Update the notification
                notifications[index] = updatedNotification;
                /* Update into DB */
                let teacherNotificationsArr = {
                    teacherNotifications: [...this._allRemoveNotification, ...notifications]
                }
                if (teacherNotificationsArr?.teacherNotifications)
                    this.userService.updateSchoolRepNotification(updatedNotification, updatedNotification.schoolRepUid);

                this._notifications.next(notifications);
                return notifications
            })
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => {
                // Go through all notifications and set them as read
                notifications.forEach((notification, index) => {
                    notifications[index].read = true;
                });
                let teacherNotificationsArr = {
                    teacherNotifications: [...this._allRemoveNotification, ...notifications].filter(msg => msg?.['approvalRequest'] != true)
                }
                if (teacherNotificationsArr?.teacherNotifications) {
                    this.userService.setTeacherInfo(teacherNotificationsArr);
                }

                this._notifications.next(notifications);
                return notifications
            })
        );

        // return this.notifications$.pipe(
        //     take(1),
        //     switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
        //         map((isUpdated: boolean) => {
        //             // Go through all notifications and set them as read
        //             notifications.forEach((notification, index) => {
        //                 notifications[index].read = true;
        //             });
        //             let teacherNotificationsArr = {
        //                 teacherNotifications: notifications
        //             }
        //             if (teacherNotificationsArr?.teacherNotifications)
        //                 this.userService.setTeacherInfo(teacherNotificationsArr)
        //             // Update the notifications
        //             this._notifications.next(notifications);

        //             // Return the updated status
        //             return isUpdated;
        //         })
        //     ))
        // );
    }
}
