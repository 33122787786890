import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {

  constructor(
    private http: HttpClient
) { }

  getAllActiveProducts() {
    let url = 'https://berrygoodmedia.herokuapp.com/https://tactileducation.myshopify.com/admin/api/2023-01/products.json?status=active'
    // let url = 'https://tactileducation.myshopify.com/admin/api/2023-01/products.json?status=active';
    const headers: any = new HttpHeaders()
      .set("X-Shopify-Access-Token", "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")
      .set("Content-Type", "application/json")
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE')
      .set('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Token, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers')
      .set('Access-Control-Allow-Credentials', 'true')
      .set('Access-Control-Allow-Origin', '*');

    const requestOptions = { headers: headers };
    return this.http.get(url, requestOptions)
  }



  getAllStates(){
    let url = 'https://berrygoodmedia.herokuapp.com/https://www.universal-tutorial.com/api/states/India'
    const headers: any = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE')
      .set('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Token, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers')
      .set('Access-Control-Allow-Credentials', 'true')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJjaGFuZHJhc2hla2hhci5hQGludGVybi50aGlua3RhYy5jb20iLCJhcGlfdG9rZW4iOiJHS3pFVmJlS2NvNkRLenRUV1NXOHI2TmRLbVEyQVVBNnR6ejF6QWRqeld6WVp1RzFtWW1lbG1xdEsxanBGV3JPejVBIn0sImV4cCI6MTY4MDM0MDE2OX0.OtyGsJ517IfVnXGAvcj6hs2_pCdhnWJHx261ALsWtmA');

    const requestOptions = { headers: headers };
    return this.http.get(url, requestOptions)
  }
  
}
