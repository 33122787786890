import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, QueryFn } from '@angular/fire/compat/firestore';
import { serverTimestamp } from '@angular/fire/firestore';
import { User } from 'app/core/dbOperations/user/user.types';
import { BehaviorSubject, Observable, ReplaySubject, first, take, tap } from 'rxjs';
import { TeacherService } from '../teachers/teachers.service';
import { Classroom } from './classroom.types';
import { ClassroomsFirestore } from './classrooms.firestore';
import { MasterService } from '../master/master.service';
import { coerceStringArray } from '@angular/cdk/coercion';

@Injectable({
    providedIn: 'root'
})
export class ClassroomsService {
    private _classrooms: ReplaySubject<User> = new ReplaySubject<User>(1);
    classroomSub = new BehaviorSubject(null)
    private dataSubject = new BehaviorSubject<any[]>(null);
    data$ = this.dataSubject.asObservable();

    currentClassroomId = new BehaviorSubject(null)
    currentClassroomName = new BehaviorSubject(null)
    allClassroomByInstituteSub = new BehaviorSubject(null)
    selectedInstitution = new BehaviorSubject(null)
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private classroomFirestore: ClassroomsFirestore,
        private teachersService: TeacherService,
        public afAuth: AngularFireAuth,
        public afs: AngularFirestore,
        private masterService: MasterService
        // private
    ) {
    }

    updateClsProgrammes(value, id: string) {
        return this.afs.collection('Classrooms').doc(id).update({
            programmes: value.programmes
        })
    }

    getClassroomId(institutionId, grade, section) {
        // this.afs.collection('Classrooms', ref =>
        //     ref.where('institutionId', '==', institutionId)
        //         .where('grade', '==', grade)
        //         .where('section', '==', section)).ref.limit(1).get().then((docRef: any) => {
        //             if (docRef.exists) {
        //                 return docRef.docs[0].data();
        //             } else {
        //                 return false
        //             }
        //         })

        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('institutionId', '==', institutionId)
                .where('grade', '==', grade)
                .where('section', '==', section);
        return this.classroomFirestore.collection$(query).pipe(take(1),
            tap((classroom: any) => {
                if (classroom.length)
                    return classroom
                else return false
            }))
    }

    getClassroomByIdOnce(id: string) {
        return this.classroomFirestore.getWithGet(id);
    }

    addNewClassroom(classroomValue: Classroom) {
        const id = this.classroomFirestore.getRandomGeneratedId();
        classroomValue.classroomId = id
        classroomValue.docId = id
        this.classroomFirestore.createWithId(classroomValue, id)
        this.masterService.addNewClassroom(classroomValue)
        return classroomValue
    }

    updateWorkFlowIdClassroom(ValueObject: any, classroomId: string) {
        this.classroomFirestore.update(ValueObject, classroomId)
    }

    getClassroomDataById(clsId: string) {
        return this.classroomFirestore.getDocDataByDocId(clsId)
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set classrooms(value: User) {
        // Store the value
        this._classrooms.next(value);
    }

    get classrooms$(): Observable<User> {
        this._classrooms.subscribe(a => {
        })

        return this._classrooms.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in classrooms data
     */

    getAllClassrooms() {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('docId', '!=', '---classroom-schema---' || '---trash---')
        return this.classroomFirestore.collection$(query)
    }

    getWorkflowAssignments(id) {
        return this.classroomFirestore.getDocDataByDocId(id)
    }

    get(id): Observable<any> {

        return this.classroomFirestore.doc$(id).pipe(take(1),
            tap((classroom) => this._classrooms.next(classroom)))
    }

    async getAllClassroom(id) {
        //    return this.teachersService.getClassroomLists(id).pipe(take(1)).subscribe(res=>{
        //        const classList = res?.classroomId
        //        return classList
        //    })
        let list = []
        const clsPromise = new Promise(resolve => {
            this.teachersService.getClassroomLists(id).pipe(take(1)).subscribe(res => {
                resolve(res?.classroomId)
            })
        })

        const classroomInfo: any = await clsPromise;

        for (let d in classroomInfo) {
            this.get(classroomInfo[d]).pipe(take(1)).subscribe(res => {
                list.push(res)
            })
        }
        this.classroomSub.next(list)
        return list
    }

    /**
     * Update the classrooms
     *
     * @param classrooms
     */
    // update(classrooms: User): Observable<any> {
    //     return this._httpClient.patch<User>('api/common/classrooms', { classrooms }).pipe(
    //         map((response) => {
    //             this._classrooms.next(response);
    //         })
    //     );
    // }

    update(value, id: string) {
        return this.classroomFirestore.update(value, id)
    }

    updateWithoutMerge(value, id: string) {
        return this.classroomFirestore.updateWidthoutMerge(value, id);
    }

    async checkClasroom(studentId, clsId) {
        let room
        const usr: any = await this.afAuth.authState.pipe(first()).toPromise();
        const query: QueryFn = (ref: CollectionReference) => ref.where('uid', '==', usr.uid).where('id', '==', studentId)

        const doc = this.teachersService.getWithQuery(query)

        const classPromise = new Promise(async resolve => {
            (await doc).pipe(take(1)).subscribe(res => {
                if (res.length == 0) {
                    room = ''
                }
                else {
                    if (res[0].classroomId == clsId) {
                        room = clsId
                    }
                    else {
                        room = res[0].classroomId
                    }
                }
                resolve(room)
            })
        })
        return classPromise
    }

    getFirstClassroom(studentId) {
        const doc = this.teachersService.getWithId(studentId)
        const studentInfo = new Promise(resolve => {
            doc.pipe(take(1)).subscribe(res => {
                resolve(res)
            })
        })
        return studentInfo
    }

    getRandomGeneratedId() {
        return this.classroomFirestore.getRandomGeneratedId()
    }

    getAllClassroomByInstitute(instituteId: string): Observable<any> {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('institutionId', '==', instituteId)
        return this.classroomFirestore.collection$(query).pipe(take(1),
            tap((classrooms) => {
                this.allClassroomByInstituteSub.next(classrooms || [])
                return this._classrooms.next(classrooms || []);
            }))
    }

    getSnapshot(query: QueryFn): Observable<any> {
        return this.classroomFirestore.collectionSnapshot(query).pipe(take(1),
            tap((classroom) => {
                return this._classrooms.next(classroom);
            }))
    }

    getWithQuery(query: QueryFn): Observable<any> {
        return this.classroomFirestore.collection$(query).pipe(take(1),
            tap((classroom) => {
                return this._classrooms.next(classroom);
            }))
    }

   async getWithQuery1(query: QueryFn) {
        return await this.classroomFirestore.collectionSnapshotOnce(query)
    }

    toTrash(docId, classInfo) {
        this.afs.collection('Classrooms').doc('---trash---').collection('DeletedClassrooms').doc(docId).set({ ...classInfo, trashAt: serverTimestamp() })
    }
    delete(docId) {
        return this.classroomFirestore.delete(docId)
    }

    trashCollection() {
        return this.afs.collection('Classrooms').doc('---trash---').collection('DeletedClassrooms').valueChanges()
    }

    deleteInTrash(docId) {
        return this.afs.collection('Classrooms').doc('---trash---').collection('DeletedClassrooms').doc(docId).delete()
    }

    updateClassroomUsingUpdate(docId, value) {
        return this.classroomFirestore.updateUsingUpdate(docId, value)
    }

    async getAllclassroomdocs(updatedProgName, classrooms, pid) {
        let docArr = []
        classrooms.forEach(async (clas) => {
            const query: QueryFn = (ref: CollectionReference) => ref.where(`classroomId`, '==', clas)
            const d = await this.classroomFirestore.collectionSnapshot(query)
            docArr.push(d)
        })
        return docArr
    }

    async removeClassroomFromTeacher(teacherDoc: any, classroomId: string) {
        const teacher = teacherDoc;
        delete teacher.classrooms[classroomId];
        this.teachersService.updateTeacherProgrammeWithoutMerge(teacher.docId, teacher);
    }

    getClassroomsByProgrammeId(programmeId: string) {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where(`programmes.${programmeId}.programmeId`, '==', programmeId);
        return this.classroomFirestore.collection$(query);
    }
}
