import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, QueryFn } from '@angular/fire/compat/firestore';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/dbOperations/user/user.types';
import { firstValueFrom, forkJoin, map } from 'rxjs';
import { BehaviorSubject, first, Observable, pipe, ReplaySubject, take, tap } from 'rxjs';
import { UserService } from '../user/user.service';
import { Student } from './student.types';
import { StudentsFirestore } from './students.firestore';

@Injectable({
    providedIn: 'root'
})
export class StudentsService {
    private _students: ReplaySubject<User> = new ReplaySubject<User>(1);
    currentStudent = new BehaviorSubject(null)
    currentStudentId = new BehaviorSubject(null)
    studentSubmissionAttempts: any;
    // authUser: aUser.User

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private studentFirestore: StudentsFirestore,
        public afAuth: AngularFireAuth,
        private userService: UserService,
        private authService: AuthService,
        private afs:AngularFirestore
    ) {
    }

    async getDocWithQuery(query: QueryFn): Promise<Observable<any>> {
        return this.studentFirestore.collection$(query).pipe(take(1),
            tap((teacher) => {
                return teacher
            }))
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set students(value: User) {
        // Store the value
        this._students.next(value);
    }

    get students$(): Observable<User> {
        // this._students.subscribe(a => {
        //     console.log(a);
        // })

        return this._students.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in students data
     */
    async get(): Promise<Observable<any>> {
        // const usr: any = await this.afAuth.authState.pipe(first()).toPromise();
        const uid = this.authService.getLogedInUid();
        const query: QueryFn = (ref: CollectionReference) => ref.where('linkUid', '==', uid)
        // const query: QueryFn = (ref: CollectionReference) => ref.where('uid', '==', 'fqawdKwpdTeUbyE5HJYPRroCypz2')

        return this.studentFirestore.collection$(query).pipe(take(1),
            tap((students) => {
                return this._students.next(students);
            }))
    }

    getWithQuery(query: QueryFn): Observable<any> {
        return this.studentFirestore.collection$(query).pipe(take(1),
            tap((students) => {
                return this._students.next(students);
            }))
    }

    create(value: Student): Promise<any> {
        return this.studentFirestore.create(value)
    }

    createWithId(value: Student, id): Promise<any> {
        return this.studentFirestore.createWithId(value, id)
    }

    getWithId(id) {
        return this.studentFirestore.doc$(id).pipe(take(1),
            tap((student) => {
                return this.currentStudent.next(student);
            }))
    }

    getClassroomLists(id) {
        return this.studentFirestore.doc$(id).pipe(take(1))
    }

    updateUser(id) {
        const doc = this.getWithId(id)
        doc.pipe(take(1)).subscribe(res => {
            this.userService.updateUser(res?.studentMeta)
        })
    }

    async checkUid(studentId) {
        const usr: any = await this.afAuth.authState.pipe(first()).toPromise();

        const doc = this.getWithId(studentId)
        let output: boolean
        const promise = new Promise(resolve => {
            doc.pipe(take(1)).subscribe(res => {
                if (res?.linkUid === usr?.uid) {
                    output = true
                }
                else {
                    output = false
                }
                resolve(output)
            })

        })

        return promise
    }

    getStudentInfo(studentId) {
        const doc = this.getWithId(studentId)
        const studentInfo = new Promise(resolve => {
            doc.pipe(take(1)).subscribe(res => {
                resolve(res)
            })
        })
        return studentInfo
    }

    /**
     * Update the students
     *
     * @param students
     */
    // update(students: User): Observable<any> {
    //     return this._httpClient.patch<User>('api/common/students', { students }).pipe(
    //         map((response) => {
    //             this._students.next(response);
    //         })
    //     );
    // }

    async checkDocUpdateStudentMeta(value, docId) {
        const checkDoc = await this.studentFirestore.getDocDataByDocId(docId);
        if (checkDoc != undefined) {
            this.studentFirestore.update(value, docId)
        }
    }

//    async updateStudent(value, docId) {
//         return this.studentFirestore.update(value, docId)

//     }

async updateStudent(value, docId) {
    try {
        await this.studentFirestore.update(value, docId);
    } catch (error) {
        console.error('Error updating student:', error);
        throw error; // Re-throw the error to be handled by the calling function if needed
    }
}

    setStudent(docId, value) {
       return this.studentFirestore.updateUsingUpdate(docId, value)
    }

    delete(doc) {
        const classrooms = { classrooms: doc.classrooms };
        return this.studentFirestore.updateCls(classrooms, doc.docId)
    }

    async getstudentDocs(classrooms, institutionId) {
        let allData = []
        classrooms.forEach(async (clas) => {
            const query: QueryFn = (ref: CollectionReference) => ref.where(`classrooms.${clas}.institutionId`, '==', institutionId)
            const d = await this.studentFirestore.collectionSnapshot(query).pipe(take(1))
            allData.push(d)
        })
        return allData
    }

    // async getAllstudentdocs(updatedProgName, classrooms, pid) {
    //     let docArr = []
    //     classrooms.forEach(async (clas) => {
    //         const query: QueryFn = (ref: CollectionReference) => ref.where(`classrooms.${clas}.classroomId`, '==', clas)
    //        // const d = await this.studentFirestore.collectionSnapshot(query)
    //         const d :any= await this.studentFirestore.collectionSnapshotOnce(query)
    //         let n:any=await firstValueFrom(d)
    //         console.log(n.data())
    //         docArr.push(d.docs.map(m=>m.data()))
    //     })
    //     return docArr

    // }

    async getAllstudentdocs(updatedProgName, classrooms, pid) {
        let docArr = [];
        await Promise.all(
            classrooms.map(async (clas) => {
                const query = (ref) => ref.where(`classrooms.${clas}.classroomId`, '==', clas);
                const d = await this.studentFirestore.collectionSnapshotOnce(query);
                const n = await firstValueFrom(d);
                docArr.push(...n.docs.map(m => m.data()));
            })
        );

        return docArr;
    }

    async getAlldoc() {
        const query: QueryFn = (ref: CollectionReference) => ref.where(`studentMeta.phoneNumber`, '!=', "")
        return await this.studentFirestore.collectionSnapshot(query);
    }


    getContestDataOfStudent(studentId:string,contestId:string){
       return this.afs.doc(`Students/${studentId}/Submissions/${studentId}-${contestId}`).get()
    }

    getStudentWithIds(ids){
        const query: QueryFn = (ref: CollectionReference) => ref.where(`contestSubmissions`, '!=', '')
        return this.studentFirestore.collectionSnapshot(query);
    }

    getAttemptedAssignmentsByAssignmentId(assignmentId: string) {
        const query: QueryFn = (ref: CollectionReference) => ref.where('attemptedAssignments', 'array-contains', assignmentId);
        return this.studentFirestore.collection$(query);
    }

    getStudentSubmissionByIdOnce(studentId: string, classroomId: string, programmeId: string) {
        return this.afs.collection('Students').doc(studentId).collection('Submissions').doc(`${classroomId}-${programmeId}`).get();
    }

    getStudentByIdOnce(studentId: string) {
        return this.afs.collection('Students').doc(studentId).get();
    }

}
