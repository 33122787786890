import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FuseDrawerService {
  drawerOpenSubject = new BehaviorSubject(false)
  drawerOpenEventSubject = new BehaviorSubject(false)
  drawerOpenNotificationSubject = new BehaviorSubject(false)
  drawerOpenTrashLUSubject = new BehaviorSubject(false)
  drawerOpenTrashInstitutesSubject = new BehaviorSubject(false)
  drawerOpenTrashClsSubject = new BehaviorSubject(false)
  drawerOpenTrashAssignmentSubject = new BehaviorSubject(false)

  drawerOpenTrashProgrammesSubject = new BehaviorSubject(false)

  constructor() { }
}
