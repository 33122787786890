import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Output() ratingInfo: EventEmitter<any> = new EventEmitter();

  rating: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  setRating(value: number) {
    this.rating = value;
    this.ratingInfo.emit(value);
  }

}
