import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { lastValueFrom } from 'rxjs';
import { WANotification } from '../models/wa-notification';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExotelService {

  otp: number

  constructor(
    private afFun: AngularFireFunctions,
    private httpClient: HttpClient
  ) { }

  generateOtp = function (size: number) {
    const zeros = '0'.repeat(size - 1);
    const x = parseFloat('1' + zeros);
    const y = parseFloat('9' + zeros);
    const confirmationCode = Math.floor(x + Math.random() * y);
    return confirmationCode;
  }

  async sendSms(exotelData: any) {
    // this.afFun.useFunctionsEmulator(`http://localhost:5000/${environment.cloudFunctionUrl}/asia-south1/get_user_login_token_from_phone`)
    let req = await this.afFun.httpsCallable('exotelSms')(exotelData)
    await lastValueFrom(req).then(res => {
      console.log(res);
    }).catch(er => console.error(er))
  }

  async createOTP(phone: string) {
    this.otp = this.generateOtp(6)
    let exotelData = {
      // docPath: d.path + '/Communications/welcome',
      To: phone,
      Body: `${this.otp.toString()} is your OTP to log in to ThinkTac's Teacher Corner Platform. Your OTP is valid for 5 minutes. -ThinkTac`,
      DltTemplateId: '1107167394121275027'
    }
    this.sendSms(exotelData)
  }

  async createOTPWhatsApp(countryCode: string, phone: string) {
    this.otp = this.generateOtp(6)
    const endUrl = `https://asia-south1-${environment.cloudFunctionUrl}.cloudfunctions.net/unlabWaWelcomeNotifications`;
    // const endUrl = `http://localhost:5000/${environment.cloudFunctionUrl}/asia-south1/unlabWaWelcomeNotifications`;
      const formData: WANotification = {
        phoneNumber: countryCode + phone,
        tmeplateName: "thinktac_login",
        params: [
          this.otp.toString()
        ],
        // isUnlabLanding: undefined
      }
      const httpOption: any = {
        responseType: 'application/json'
      };
      await this.httpClient.post<any>(endUrl, formData, httpOption).toPromise().then((response) => {
        console.log(response);
      }).catch(error => {
        console.log(error)
      });
  }

  async createNewOTP(phone: string) {
    this.otp = this.generateOtp(6)
    let exotelData = {
      // docPath: d.path + '/Communications/welcome',
      To: phone,
      Body: `${this.otp.toString()} is your OTP to change your registered mobile number on
       ThinkTac's UnLab Platform. Your OTP is valid for 5 minutes.-ThinkTac`,
      DltTemplateId: '1107167577387467790'
    }
    this.sendSms(exotelData)
  }

}
