import { Injectable } from '@angular/core';
import { ContestFirestore } from './contest.firestore';
import { CollectionReference, QueryFn, AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, map, of, switchMap, take, tap, throwError } from 'rxjs';
import { Category, FirstStageSubmType, firstStageAllCourses } from './contest.types';
import { HttpClient } from '@angular/common/http';
import { arrayUnion, serverTimestamp } from '@angular/fire/firestore';
import { NavigationService } from 'app/core/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ContestService {
  allContest

  private _categories: BehaviorSubject<Category[] | null> = new BehaviorSubject(null);
  private _course: BehaviorSubject<FirstStageSubmType | null> = new BehaviorSubject(null);
  private _courses: BehaviorSubject<FirstStageSubmType[] | null> = new BehaviorSubject(null);
  private _submissions: BehaviorSubject<FirstStageSubmType[] | null> = new BehaviorSubject(null);
  currentContest: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private contestFireStore: ContestFirestore,
    private afs: AngularFirestore,
    // private navigationService: NavigationService,
    private _httpClient: HttpClient) { }
  get(docId: string) {
    return this.contestFireStore.doc$(docId)
  }
  updateContest(value, id): Promise<any> {
    return this.contestFireStore.update(value, id)
  }
  addContest(value): Promise<any> {
    return this.contestFireStore.create(value)
  }
  getAllContest() {
    const query: QueryFn = (ref: CollectionReference) => ref.where('docId', 'not-in', ['--contest--', '--contest-schema--', '--deleted-contest--'])
    return this.contestFireStore.collection$(query)
  }
  addTrashContest(docId, value): Promise<any> {
    /* /Contests/--deleted-contest--/--trash-contests-- */
    return this.afs.collection('Contests').doc('--deleted-contest--').collection('--trash-contests--').doc(docId).set(value)
  }
  deleteContest(docId): Promise<any> {
    return this.contestFireStore.delete(docId)
  }
  getRandomId(): string {
    return this.contestFireStore.getRandomGeneratedId()
  }
  addNewSubmission(contestId, stagesSubmArr) {
    return this.afs.collection('Contests').doc(contestId).update({ stagesNames: stagesSubmArr, updatedAt: serverTimestamp() });
    // this.contestFireStore.update(contestId, submData)
  }

  getCategory(std) {
    let category = 'junior';
    if (std >= 8 && std <= 10)
      category = 'senior';
    else if (std >= 5 && std <= 7)
      category = 'intermediate';
    return category
  }
  /**
   * Getter for categories
   */
  get categories$(): Observable<Category[]> {
    return this._categories.asObservable();
  }

  /**
   * Getter for courses
   */
  get courses$(): Observable<FirstStageSubmType[]> {
    return this._courses.asObservable();
  }

  /**
   * Getter for course
   */
  get course$(): Observable<FirstStageSubmType> {
    return this._course.asObservable();
  }

  /**
   * Get categories
   */
  getCategories(): Observable<Category[]> {
    return this._httpClient.get<Category[]>('api/apps/academy/categories').pipe(
      tap((response: any) => {
        this._categories.next(response);
      })
    );
  }

  /**
   * Get courses
   */

  getContestSubmissions(query: QueryFn ,contestCollectionId){
    return this.afs.collection(contestCollectionId,query).get()
  }
  getStages(stageArr, stageId) {
    const selectedStageInfo = stageArr.find(stage => stage.stageId == stageId);
    // console.log(selectedStageInfo);


    return selectedStageInfo
    // const allCourses: FirstStageSubmType = {
    //   id: selectedStageInfo.stageNumber,
    //   title: selectedStageInfo.stageName,
    //   // imagePath: string;
    //   // slug?: string;
    //   // description?: string;
    //   // category?: string;
    //   // duration?: number;
    //   steps?: {
    //     order?: number;
    //     title?: string;
    //     subtitle?: string;
    //     content?: string;
    //   }[];
    //   totalSteps?: number;
    //   updatedAt?: number;
    //   featured?: boolean;
    //   progress?: {
    //     currentStep?: number;
    //     completed?: number;
    //   };
    // }

  }
  checkWorkflowId(stagesArr, submId) {
    const submData = stagesArr?.flatMap(stage => stage?.submissions || [])?.find(subm => subm?.submissionId == submId);
    return submData;
    // return submData?.workflowId || '';
    // const checkStage = selectedContest?.stagesNames?.find(stage => stage?.stageId == stageId);
    // let checkSubm
    // if (checkStage) {
    //   checkSubm = checkStage.submissions.find(subm => subm.submissionId == submId);
    // }
    // if (checkSubm && checkSubm?.workflowId) {
    //   return checkSubm?.workflowId
    // }
  }

  addWFIdIntoStageSubm(stagesArr, submId, workflowId) {
    for (let i = 0; i < stagesArr.length; i++) {
      const obj = stagesArr[i];
      if (obj.submissionId === submId) {
        obj['workflowId'] = workflowId;
        return;
      }
      if (obj.submissions) {
        this.addWFIdIntoStageSubm(obj.submissions, submId, workflowId);
      }
    }
    return stagesArr
  }

  getCourses(): Observable<FirstStageSubmType[]> {
    // const allCourses: FirstStageSubmType[] = firstStageAllCourses
    // const allStages = this.navigationService.submissionContestSubject$.getValue;
    // console.log(allStages);

    const allCourses: FirstStageSubmType[] = firstStageAllCourses
    this._courses.next(allCourses)
    return of(firstStageAllCourses)
  }

  /* *
  *Get Selectted Contest data
  */
  getSelectedContest(docId: string): Observable<any> {
    return this.contestFireStore.doc$(docId);
  }

  /**
   * Get course by id
   */

  getCourseById(id: string): Observable<FirstStageSubmType> {
    return this.getCourses().pipe(
      take(1),
      map((courses: any) => {
        const contents = courses.find(course => course.id == id)
        // Update the course
        this._course.next(contents);
        // Return the course
        return contents;
      }),
      switchMap((course) => {
        if (!course) {
          return throwError('Could not found course with id of ' + id + '!');
        }
        return of(course);
      })
    );
  }
}
