import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from 'app/core/dbOperations/user/user.types';
import { serverTimestamp } from "firebase/firestore";
import { BehaviorSubject, map, Observable, ReplaySubject, take, tap } from 'rxjs';
import { LearningUnitsFirestore } from './learningUnits.firestore';
import { filter } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class LearningUnitsService {
    private _learningUnits = new ReplaySubject(1);
    currentLearningUnitsName = new BehaviorSubject(null);
    isassociatedContentChanged = new BehaviorSubject(null);
    allLUdataFromcoll=new BehaviorSubject(null);
    // collectionName = 'CopyOfLearningUnits';
    collectionName = 'LearningUnits';
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private afs: AngularFirestore,
        private learningUnitsFirestore: LearningUnitsFirestore) {
    }
    updateLU(docId, value) {
      return this.learningUnitsFirestore.update(value, docId)
    }
    async addNewLU(luCollObj) {
        return this.afs.collection(this.collectionName).doc(luCollObj.docId).set({ ...luCollObj, creationDate: serverTimestamp(), updatedAt: serverTimestamp() }, { merge: true })
    }
    deleteLU(docId) {
        return this.learningUnitsFirestore.delete(docId)
    }
    trashLU(docId, luDetails) {
        this.afs.collection(this.collectionName).doc('--trash-learning-units--').collection('versions').doc(docId).set({ ...luDetails, trashAt: serverTimestamp() })
    }
    deleteFromTrashLU(docId) {
        return this.afs.collection(this.collectionName).doc('--trash-learning-units--').collection('versions').doc(docId).delete();
    }
    async getTrashLU(luCode) {
        return await this.afs.collection(this.collectionName).doc('--trash-learning-units--').collection('versions', ref => ref.where('code', '==', luCode)).get().toPromise().then(doc => doc?.docs || [])
    }
    async getAllTrashLU() {
        return this.afs.collection(this.collectionName).doc('--trash-learning-units--').collection('versions').valueChanges();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */


    set learningUnitss(value: User) {
        // Store the value
        this._learningUnits.next(value);
    }

    get learningUnitss$(): Observable<User> {
        this._learningUnits.subscribe(a => {
        })

        return this._learningUnits.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in learningUnitss data
     */
    get(id): Observable<any> {
        return this.learningUnitsFirestore.doc$(id).pipe(take(1),
            tap((learningUnits) => this._learningUnits.next(learningUnits)))
    }

   async getLearningUnitData(id) {
        return this.learningUnitsFirestore.getDocDataByDocId(id)
    }

    /**
     * Update the learningUnitss
     *
     * @param learningUnitss
     */
    update(learningUnitss: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/learningUnitss', { learningUnitss }).pipe(
            map((response) => {
                this._learningUnits.next(response);
            })
        );
    }

    getAllLUFromLUcoll(){
        return this.afs.collection('LearningUnits', ref => ref.where('docId', '!=', '')).get()
    }

    getLUbyquery(id){
        return this.afs.collection('LearningUnits', ref => ref.where('learningUnitId', '==', id)).get()
    }

    getLUByIdOnce(docId: string) {
        return this.learningUnitsFirestore.getWithGet(docId);
    }

}
