import { Inject, Injectable } from "@angular/core";
import { AngularFirestore, QueryFn } from "@angular/fire/compat/firestore";
import { environment } from "environments/environment";
import { serverTimestamp } from "firebase/firestore";
import { async, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export abstract class FirestoreService<T> {

    protected abstract basePath: string;
    uid$: Observable<string>

    constructor(
        @Inject(AngularFirestore) protected afs: AngularFirestore,
        // protected authService: AuthService
    ) {
        // this.uid$ = this.authService.authUserSubject.pipe(map((user: User) => user.uid))
    }

    async getDocDataByDocId(docId: string) {
        return this.afs.doc(`${this.basePath}/${docId}`).get().toPromise().then(d => d.data())
    }

    getStreamingDocDataByDocId(docId: string) {
        return this.afs.doc(`${this.basePath}/${docId}`).valueChanges()
    }

    getRandomGeneratedId() {
        return this.afs.createId();
    }

    doc$(docId: string): Observable<T> {
        return this.afs.doc<T>(`${this.basePath}/${docId}`).valueChanges().pipe(
            tap(r => {
                if (!environment.production) {
                    console.groupCollapsed(`Firestore Streaming [${this.basePath}] [doc$] ${docId}`)
                    console.log(r)
                    console.groupEnd()
                }
            }),
        );
    }

    collection$(queryFn?: QueryFn): Observable<T[]> {
        return this.afs.collection<T>(`${this.basePath}`, queryFn).valueChanges().pipe(
            tap(r => {
                if (!environment.production) {
                    console.groupCollapsed(`Firestore Streaming [${this.basePath}] [collection$]`)
                    console.table(r)
                    console.groupEnd()
                }
            }),
        );
    }

    collectionSnapshot(queryFn: QueryFn) {
        return this.afs.collection<T>(`${this.basePath}`, queryFn).snapshotChanges().pipe(
            tap(r => {

                if (!environment.production) {
                    console.groupCollapsed(`Firestore Streaming [${this.basePath}] [collection$]`)
                    console.table(r)
                    console.groupEnd()
                }
            }),
        );
    }

    create(value: T) {
        const docId = this.afs.createId();
        return this.collection.doc(docId).set(Object.assign({}, { docId, createdAt: serverTimestamp() }, value)).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
            return _
        })
    }

    collectionSnapshotOnce(queryFn: QueryFn) {
        return this.afs.collection<T>(`${this.basePath}`, queryFn).get().pipe(
            tap(r => {

                if (!environment.production) {
                    console.groupCollapsed(`Firestore Streaming [${this.basePath}] [collection$]`)
                    console.table(r)
                    console.groupEnd()
                }
            }),
        );
    }

    // addDocNestedArr(value: T) {
    //     return this.collection.add({ ...value }).then(_d => {
    //         if (!environment.production) {
    //             console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
    //             console.log('[docId]', _d, value)
    //             console.groupEnd()
    //         }
    //         return _d
    //     })
    // }

    createWithId(value: T, docId) {
        return this.collection.doc(docId).set(Object.assign({}, { docId, createdAt: serverTimestamp() }, value)).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
            return _
        })
    }

    addClassroom(value: T, docId) {
        return this.collection.doc(docId).set(Object.assign({}, { docId, createdAt: serverTimestamp() }, value)).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
            return _
        })
    }

    update(value: T, docId: string) {
        return this.collection.doc(docId).set(Object.assign({}, { docId, updatedAt: serverTimestamp() }, value,), { merge: true }).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
        })
    }

    updateWidthoutMerge(value: T, docId: string) {
        return this.collection.doc(docId).set(Object.assign({}, { docId, updatedAt: serverTimestamp() }, value,)).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
        })
    }

    updateCls(value: T, docId: string) {
        return this.collection.doc(docId).update(Object.assign({}, { docId, updatedAt: serverTimestamp() }, value)).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
        })
    }
    updateUsingUpdate(docId, value) {
        return this.collection.doc(docId).update(Object.assign({}, { docId, updatedAt: serverTimestamp() }, value)).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
        })
    }

    updateArrayUnion(value: T, docId: string) {
        return this.collection.doc(docId).set({ value }, { merge: true }).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [create]`)
                console.log('[docId]', docId, value)
                console.groupEnd()
            }
        })
    }

    delete(docId: string) {
        return this.collection.doc(docId).delete().then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [delete]`)
                console.log('[docId]', docId)
                console.groupEnd()
            }
        })
    }

    getQueryWithGet(queryFn?: QueryFn) {
        return this.afs.collection<T>(`${this.basePath}`, queryFn).get()
    }

    getWithGet(docId: string): Observable<any> {
        return this.collection.doc(docId).get()
    }

    getBatchRef() {
        this.afs.firestore.batch();
    }

    private get collection() {
        return this.afs.collection(`${this.basePath}`);
    }

    getDocuments(idArr, type) {
        // return this.collection.
    }

    trashDocument(document: any, docId: string, trashId: string, trashSubCollectionName: string) {
        this.afs.collection(this.basePath).doc(trashId).collection(trashSubCollectionName).doc(docId).set({ ...document, trashAt: serverTimestamp() }).then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [trashed]`);
                console.log('[docId]', docId);
                console.groupEnd();
            };
        });
    }

    showAllTrashDocuments(trashId: string, trashSubCollectionName: string) {
        return this.afs.collection(this.basePath).doc(trashId).collection(trashSubCollectionName).valueChanges().pipe(
            tap(r => {
                if (!environment.production) {
                    console.groupCollapsed(`Firestore Streaming [${this.basePath}] [collection$]`)
                    console.table(r)
                    console.groupEnd()
                }
            }),
        );
    }

    deleteDocumentFromTrashPermanently(docId: string, trashId: string, trashSubCollectionName: string) {
        return this.afs.collection(this.basePath).doc(trashId).collection(trashSubCollectionName).doc(docId).delete().then(_ => {
            if (!environment.production) {
                console.groupCollapsed(`Firestore Service [${this.basePath}] [trash deleted]`);
                console.log('[docId]', docId);
                console.groupEnd();
            };
        });
    }

}
