import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, take, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { UserService } from 'app/core/dbOperations/user/user.service';
import { StudentsTeachersInfoComponent } from 'app/modules/students-teachers-info/students-teachers-info.component';
import { SelfRegistrationUserApprovalComponent } from 'app/modules/admin/self-registration-user-approval/self-registration-user-approval.component';
import { FuseDrawerService } from 'app/core/dbOperations/fuseDrawerService/fuse-drawer.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`
    :host::ng-deep .fuse-drawer-content{
        background-color: #F1F5F9 !important;
    }`],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: Notification[] = [];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    drawerOpened: any = false;
    component
    user
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private drawerService: FuseDrawerService,
        private _viewContainerRef: ViewContainerRef,
        private userService: UserService
    ) {
        this.drawerService.drawerOpenNotificationSubject.subscribe(res => {
            this.drawerOpened = res
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Subscribe to notification changes
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {
                // Load the notifications
                // console.log(this.notifications);
                this.notifications = notifications.filter(message => message['remove'] != true);
                // console.log(this.notifications);
                
                // Calculate the unread count
                this._calculateUnreadCount();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // this._notificationsService.assignmentsNotificationsSub.subscribe((assignment: any) => {
        //     this.notifications = [...assignment, ...this.notifications].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        //     // Calculate the unread count
        //     this._calculateUnreadCount();
        //     // Mark for check
        //     this._changeDetectorRef.markForCheck();

        // })

        this.userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: any) => {
                this.user = user?.teacherMeta;
            });
    }

    async acceptApproval(notificationObj: Notification) {
        const selfRegUserId = notificationObj?.selfRegUserId || '';
        const approvalObj = {
            selfRegTeacherApproval: {
                [`${notificationObj.classroomId}`]: {
                    approvalStatus: true,
                    classroomId: `${notificationObj.classroomId}`
                }
            }
        }
        if (selfRegUserId) {
            this.userService.updateSelfRegUserApproval(approvalObj, selfRegUserId);
            notificationObj.read = true;
            notificationObj.remove = true;
            notificationObj.actionTakenBy = `${this.user?.firstName || ''} ${this.user?.lastName || ''}`
            notificationObj.actionDate = new Date()
            this.delete(notificationObj)
        }
    }

    async rejectApproval(notificationObj: Notification) {
        notificationObj.read = true;
        notificationObj.remove = true;
        notificationObj.actionTakenBy = `${this.user?.firstName || ''} ${this.user?.lastName || ''}`
        notificationObj.actionDate = new Date()
        this.delete(notificationObj)
    }

    async viewRequest() {
        await import('../../../modules/admin/self-registration-user-approval/self-registration-user-approval.module').then(() => {
            this.component = SelfRegistrationUserApprovalComponent
        })
        this.drawerService.drawerOpenNotificationSubject.next(true)
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService.update(notification.id, notification).subscribe();




    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        notification.remove = true;
        this._notificationsService.delete(notification.id, notification).subscribe();
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;
    }
}
