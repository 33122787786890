import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  constructor() { }

  checkClassroom() {

  }
  getAllClassroom() { }

}
