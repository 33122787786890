import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WANotification } from 'app/core/models/wa-notification';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  isWhatsappBtnVisible = true
  constructor(private http: HttpClient) { }


  async sendWhatsAppNotification(phone: string, templateName: string, params: string[]) {
    const endUrl = `https://asia-south1-${environment.cloudFunctionUrl}.cloudfunctions.net/unlabWaWelcomeNotifications`;
    const formData: WANotification = {
      phoneNumber: phone,
      tmeplateName: templateName,
      params: params
      // params: [
      //   teacher.name,
      // ],
    }
    const httpOption: any = {
      responseType: 'application/json'
    };
    await this.http.post<any>(endUrl, formData, httpOption).toPromise().then((response) => {
    }).catch(error => {
      console.log(error)
    });
  }

  async sendWhatsAppNotificationWithImg(phone: string, templateName: string, paramsArr: string[], imgUrl) {
    const endUrl = `https://asia-south1-${environment.cloudFunctionUrl}.cloudfunctions.net/waNotificationWithImage`;
    // const endUrl = `http://localhost:5000/jigyasa-e3fbb/asia-south1/waNotificationWithImage`;

    const phoneNumber = phone;
    const tmeplateName = templateName;
    const params = paramsArr;
    const imageUrl = imgUrl

    const formData = {
      phoneNumber: phoneNumber,
      tmeplateName: tmeplateName,
      params: params,
      imageUrl: imageUrl
    }

    const httpOption: any = {
      responseType: 'application/json'
    };
    return await this.http.post<any>(endUrl, formData, httpOption).toPromise().then((response) => {

    }).catch(error => {
      console.log(error)
    });
  }

  async sendToCloudFunction(endUrl: string, objectToCreate: any) {
    const formData = {
      ...objectToCreate
    };
    const httpOption: any = {
      responseType: 'application/json'
    };

    const responseObject: any = {};

    await this.http.post<any>(endUrl, formData, httpOption).toPromise().then((res: any) => {
      responseObject.success = res;
      console.error(res);
    }).catch(error => {
      responseObject.error = error;
      console.error(error);
    });

    return responseObject;
  }

}
