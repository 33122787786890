import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChildActivationEnd, Router } from '@angular/router';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { Navigation } from 'app/core/navigation/navigation.types';
import { BehaviorSubject, filter, first, firstValueFrom, lastValueFrom, map, Observable } from 'rxjs';
import { ContestService } from '../dbOperations/contests/contest.service';
import { TeacherService } from '../dbOperations/teachers/teachers.service';
import { UserService } from '../dbOperations/user/user.service';
import { ClassroomsService } from '../dbOperations/classrooms/classrooms.service';
import { InstitutionsService } from '../dbOperations/institutions/institutions.service';
import { EventService } from '../dbOperations/events/event.service';
import { Conditional } from '@angular/compiler';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    classroomNavSubject$ = new BehaviorSubject<Navigation>(null)
    submissionContestSubject$: any = new BehaviorSubject<Navigation>(null)

    constructor(
        private _httpClient: HttpClient,
        private userService: UserService,
        private contestService: ContestService,
        private teacherService: TeacherService,
        private eventService: EventService,
        private classroomService: ClassroomsService,
        private institutionService: InstitutionsService,
        private router: Router) {
    }

    get navigation$(): Observable<Navigation> {
        return this.classroomNavSubject$.asObservable();
    }

    getInstituteData(currentValue) {
        const instObj: FuseNavigationItem = {
            id: currentValue.institutionId,
            title: currentValue.institutionName,
            type: 'collapsable',
            icon: 'feather:institute2',
            link: '/dashboard',
            active: true
        }
        return instObj
    }

    getClassroomData(currentClassValue: any) {
        if (currentClassValue.type !== 'STEM-CLUB') {
            const instObj: FuseNavigationItem = {
                id: currentClassValue['classroomId'],
                title: currentClassValue['classroomName'],
                type: 'basic',
                icon: 'feather:classroom2',
                // link: '/dashboard',
                active: false,
                link: `dashboard/${currentClassValue.classroomId}`,
                queryParams: { institutionId: currentClassValue.institutionId, classroomId: currentClassValue.classroomId, programmeId: currentClassValue.programmes[0].programmeId },
                meta: { id: 'classrooms', title: 'Classrooms', type: 'CLASSROOM' },
            }
            return instObj;
        };
    }

    getStemClubData(currentStemClubValue: any) {
        if (currentStemClubValue.type !== 'CLASSROOM') {
            const instObj: FuseNavigationItem = {
                id: currentStemClubValue['classroomId'],
                title: currentStemClubValue['stemClubName'],
                type: 'basic',
                icon: 'feather:classroom2',
                // link: '/dashboard',
                active: false,
                link: `dashboard/${currentStemClubValue.classroomId}`,
                queryParams: { institutionId: currentStemClubValue.institutionId, classroomId: currentStemClubValue.classroomId, programmeId: currentStemClubValue.programmes[0].programmeId },
                meta: { id: 'stemclubs', title: 'Stem Clubs', type: 'STEM-CLUB' }
            }
            return instObj
        };
    }

    async get() {
        let instituteClassroom
        const currUserUid: string = await this.userService.getUid();
        const checkUserAccessLevel: any = await this.userService.getDocDataById(currUserUid);
        // const teacherClassrooms = await firstValueFrom(this.teacherService.getAllTeacherClassroom(currUserUid).pipe(map(d => d?.classrooms)));
        this.teacherService.getAllTeacherClassroom(currUserUid).pipe(map(d => d?.classrooms)).subscribe(async teacherClassrooms => {
            let navItemsTemp: FuseNavigationItem[] = []
            /*
            // for separate stem clubs menu
            const stemClubsArr: any = this.getStemClubs(teacherClassrooms, checkUserAccessLevel?.accessLevel);
            */

            if (checkUserAccessLevel?.accessLevel >= 10) {
                navItemsTemp = this.getAdminView()
                let contestArr: any = await this.getContestDocs()
                let eventArr: any = await this.getAllevents(checkUserAccessLevel?.accessLevel)
                let nominationArr: any = this.nominationNav()
                // let events = this.events()
                // navItemsTemp.unshift(...events)
                navItemsTemp.unshift(...nominationArr)
                navItemsTemp.unshift(...contestArr)
                // if (checkUserAccessLevel.uid !== 'IaQ6rggGTwdXNAXmt9RcnXHnnHt1') { // hide other menus for sriram only (stem club demo)
                navItemsTemp.unshift(...eventArr)
                // }
                /*
                // for separate stem clubs menu
                navItemsTemp.unshift(...stemClubsArr)
                */
            } else {
                await this.getContestDocs()
                let nominationArr: any = this.nominationNav()
                // let events = this.events()
                let eventArr: any = await this.getAllevents(checkUserAccessLevel?.accessLevel)
                // navItemsTemp.unshift(...events)
                if (nominationArr.length) {
                    navItemsTemp.unshift(...nominationArr)
                }
                // if (checkUserAccessLevel.uid !== 'IaQ6rggGTwdXNAXmt9RcnXHnnHt1') { // hide other menus for sriram only (stem club demo)
                navItemsTemp.unshift(...eventArr)
                // }
                /*
                // for separate stem clubs menu
                navItemsTemp.unshift(...stemClubsArr)
                */
            }

            /*
            // for separate stem clubs menu
            let insClsArr: any = Object?.values(teacherClassrooms || {}).filter((classroom: any) => classroom.hasOwnProperty('type') && classroom.type === 'CLASSROOM');
            */
            // /*
            // for collapsing menu
            let insClsArr: any = Object?.values(teacherClassrooms || {});
            // */
            insClsArr = insClsArr.sort((a, b) => (b.institutionName.localeCompare(a.institutionName)));
            const groupedClassroomsByInstitution = this.getGroupedClassroomsByInstitution(insClsArr);
            instituteClassroom = insClsArr.reduce((acc: any, currentValue: any, arr: any) => {
                const index = acc.findIndex(existsCls => existsCls['id'] === currentValue['institutionId'])
                const instData = this.getInstituteData(currentValue);

                // /*
                // for collapsing menu
                const clsData = this.getClassroomData(currentValue);
                const stemClubData = this.getStemClubData(currentValue);
                // */

                /*
                // for separate stem clubs menu
                const clsData = this.getClassroomData(currentValue);
                */

                if (index >= 0) {
                    /*
                    // for separate stem clubs menu
                    acc[index].children.unshift(clsData)
                    */

                    // /*
                    // for collapsing menu
                    const targetKey = currentValue.type === 'CLASSROOM' ? 'classrooms' : 'stemclubs';
                    const targetChild = acc[index]?.children.find(child => child.id === targetKey)?.children;

                    // if (targetChild) {
                    //     targetChild.unshift(currentValue.type === 'CLASSROOM' ? clsData : stemClubData);
                    // };
                    // if (currentValue.type == 'CLASSROOM') {
                    //     acc[index]?.children.filter((child: any) => child.id === 'classrooms')?.[0]?.children?.unshift(clsData);
                    // }
                    // else {
                    //     acc[index]?.children.filter((child: any) => child.id === 'stemclubs')?.[0]?.children?.unshift(stemClubData);
                    // };
                    // */

                    const classroomsForCurrentInstitution = groupedClassroomsByInstitution[currentValue.institutionId];
                    const classroomTypesForCurrentInstitution = classroomsForCurrentInstitution.map((classroom: any) => classroom.type);


                    if (classroomTypesForCurrentInstitution.includes('STEM-CLUB') && classroomTypesForCurrentInstitution.includes('CLASSROOM')) {

                        targetChild.unshift(currentValue.type === 'CLASSROOM' ? clsData : stemClubData);
                    }

                    if (!classroomTypesForCurrentInstitution.includes('STEM-CLUB') && classroomTypesForCurrentInstitution.includes('CLASSROOM')) {
                        acc[index].children.unshift(clsData)
                    }
                    if (classroomTypesForCurrentInstitution.includes('STEM-CLUB') && !classroomTypesForCurrentInstitution.includes('CLASSROOM')) {
                        targetChild.unshift(stemClubData);
                    }

                } else {
                    /*
                    // for separate stem clubs menu
                    acc.unshift({
                        ...instData,
                        children: [clsData]
                    })
                    */

                    // /*
                    // for collapsing menu
                    acc.unshift({
                        ...instData,
                        children: []
                    });

                    const classroomsForCurrentInstitution = groupedClassroomsByInstitution[currentValue.institutionId];
                    const classroomTypesForCurrentInstitution = classroomsForCurrentInstitution.map((classroom: any) => classroom.type);
                    // const classroomSet = Array.from(new Set(classroomTypesForCurrentInstitution));

                    // if(classroomSet.length && classroomSet[0] === 'CLASSROOM' ) {
                    // if both classrooms and stem clubs then show sub level


                    if (classroomTypesForCurrentInstitution.includes('STEM-CLUB') && classroomTypesForCurrentInstitution.includes('CLASSROOM')) {


                        if (classroomTypesForCurrentInstitution.includes('STEM-CLUB')) {
                            acc[0].children.unshift({
                                id: "stemclubs",
                                title: "STEM Clubs",
                                type: "collapsable",
                                icon: "feather:classroom2",
                                active: true,
                                children: currentValue.type === 'STEM-CLUB' ? [stemClubData] : []
                            });
                            // acc.filter((classroom: any) => classroom.id === currentValue.institutionId).map((child: any) => child.children.unshift({
                            //     id: "stemclubs",
                            //     title: "STEM Clubs",
                            //     type: "collapsable",
                            //     icon: "feather:classroom2",
                            //     // link: '/dashboard',
                            //     active: true,
                            //     children: currentValue.type === 'STEM-CLUB' ? [stemClubData] : []
                            // }));
                        };

                        if (classroomTypesForCurrentInstitution.includes('CLASSROOM')) {
                            acc[0].children.unshift({
                                id: "classrooms",
                                title: "Classrooms",
                                type: "collapsable",
                                icon: "feather:classroom2",
                                active: true,
                                children: currentValue.type === 'CLASSROOM' ? [clsData] : []
                            });
                            // acc.filter((classroom: any) => classroom.id === currentValue.institutionId).map((child: any) => child.children.unshift({
                            //     id: "classrooms",
                            //     title: "Classrooms",
                            //     type: "collapsable",
                            //     icon: "feather:classroom2",
                            //     // link: '/dashboard',
                            //     active: true,
                            //     children: currentValue.type === 'CLASSROOM' ? [clsData] : []
                            // }));
                        };
                        // if either classrooms or stem clubs only then hide sub level
                    }

                    if (!classroomTypesForCurrentInstitution.includes('STEM-CLUB') && classroomTypesForCurrentInstitution.includes('CLASSROOM')) {


                        acc[0].children.unshift({ ...clsData });
                    }
                    if (classroomTypesForCurrentInstitution.includes('STEM-CLUB') && !classroomTypesForCurrentInstitution.includes('CLASSROOM')) {


                        acc[0].children.unshift({
                            id: "stemclubs",
                            title: "STEM Clubs",
                            type: "collapsable",
                            icon: "feather:classroom2",
                            active: true,
                            children: currentValue.type === 'STEM-CLUB' ? [stemClubData] : []
                        });
                    }


                    // else {
                    //     console.log('else');
                    //     if (classroomSet[0] === 'CLASSROOM') {
                    //         acc[0].children.unshift({ ...clsData });
                    //     };

                    //     if (classroomSet[0] === 'STEM-CLUB') {
                    //         acc[0].children.unshift({ ...stemClubData });
                    //     };
                    // };
                    // */
                };

                // /*
                // for collapsing menu
                // sort the array in ascending order of classroom names (title)
                // this.sortInstitutionsAndClassrooms(acc);
                // */

                return acc;
            }, navItemsTemp.length == 2 || navItemsTemp.length == 3 || navItemsTemp.length == 4 || navItemsTemp.length == 5 ? navItemsTemp : navItemsTemp.slice(-2));
            const navObj: any = { 'default': instituteClassroom };
            this.classroomNavSubject$.next(navObj);
        });
    }

    getGroupedClassroomsByInstitution(inputArray) {
        // Initialize an empty object to hold groups
        let groupedByInstitutionId = {};

        // Iterate over each object in the input array
        for (let i = 0; i < inputArray.length; i++) {
            let obj = inputArray[i];
            // Check if the group for the current institutionId already exists
            if (!groupedByInstitutionId[obj.institutionId]) {
                // If not, create a new array for this institutionId
                groupedByInstitutionId[obj.institutionId] = [];
            }
            // Add the current object to the group
            groupedByInstitutionId[obj.institutionId].push(obj);
        }

        // Return the grouped object
        return groupedByInstitutionId;
    }


    /*
    // forEach loop is slower than classic for loop
    getGroupedClassroomsByInstitution(inputArray: Array<any>) {
        // Grouping objects by institutionId
        let groupedByInstitutionId = {};

        inputArray.forEach(obj => {
            if (!groupedByInstitutionId[obj.institutionId]) {
                groupedByInstitutionId[obj.institutionId] = [];
            }
            groupedByInstitutionId[obj.institutionId].push(obj);
        });

        return groupedByInstitutionId;
    }
    */

    sortInstitutionsAndClassrooms(data: Array<any>) {
        // sort the institutions by title
        // data.sort((a, b) => a.title.localeCompare(b.title));

        // sort the classrooms by title within each institution
        data.forEach(institution => {
            if (institution.children) {
                institution.children.forEach(child => {
                    if (child.children) {
                        child.children.sort((a, b) => {
                            const aClassroomParts = a.title.split(' ');
                            const bClassroomParts = b.title.split(' ');

                            const aClassNumber = parseInt(aClassroomParts[0]);
                            const bClassNumber = parseInt(bClassroomParts[0]);

                            if (aClassNumber !== bClassNumber) {
                                return aClassNumber - bClassNumber;
                            }

                            return aClassroomParts[1].localeCompare(bClassroomParts[1]);
                        });
                    }
                });
            }
        })

        return data;
    }

    // async get() {
    //     let instituteClassroom
    //     const currUserUid: string = await this.userService.getUid();
    //     const checkUserAccessLevel: any = await this.userService.getDocDataById(currUserUid);
    //     let navItemsTemp: FuseNavigationItem[] = []
    //     if (checkUserAccessLevel?.accessLevel == 10) {
    //         navItemsTemp = this.getAdminView()
    //         let contestArr: any = await this.getContestDocs()
    //         let nominationArr: any = this.nominationNav()
    //         navItemsTemp.unshift(...nominationArr)
    //         navItemsTemp.unshift(...contestArr)
    //     } else {
    //         let contestArr: any = await this.getContestDocs()
    //         let nominationArr: any = this.nominationNav()
    //         if (nominationArr.length) {
    //             navItemsTemp.unshift(...nominationArr)
    //         }
    //     }
    //     this.teacherService.getAllTeacherClassroom(currUserUid).pipe(map(d => d?.classrooms)).subscribe(classrooms => {
    //         let insClsArr: any = Object?.values(classrooms || {});
    //         insClsArr = insClsArr.sort((a, b) => (b.institutionName.localeCompare(a.institutionName)))
    //         instituteClassroom = insClsArr.reduce((acc: any, currentValue: any, arr: any) => {
    //             const index = acc.findIndex(existsCls => existsCls['id'] === currentValue['institutionId'])
    //             const instData = this.getInstituteData(currentValue);
    //             const clsData = this.getClassroomData(currentValue);
    //             // let isClassValid = await this.checkProgrammes(currentValue);
    //             // let isInstituteValid = await this.checkInstitute(currentValue);
    //             if (index >= 0) {
    //                 // if (isClassValid) {
    //                 acc[index].children.unshift(clsData)
    //                 // }
    //             } else {
    //                 // if (isInstituteValid) {
    //                 // if (isClassValid) {
    //                 acc.unshift({
    //                     ...instData,
    //                     children: [clsData]
    //                 })
    //                 // }
    //                 // }
    //             }
    //             return acc;
    //         }, navItemsTemp.length == 2 || navItemsTemp.length == 3 ? navItemsTemp : navItemsTemp.slice(-2));
    //         const navObj: any = { 'default':  instituteClassroom };
    //         this.classroomNavSubject$.next(navObj);
    //     })
    // }
    // async checkInstitute(classInfo) {
    //     const doc = await lastValueFrom(this.institutionService.getWithId(classInfo?.institutionId))
    //     if (doc) { return true }
    //     return false
    // }
    // async checkProgrammes(classInfo) {
    //     if (classInfo?.programmes?.length == 1) {
    //         const clsDoc = await lastValueFrom(this.classroomService.get(classInfo?.classroomId))
    //         const programExist = clsDoc ? clsDoc?.programmes?.hasOwnProperty(classInfo?.programmes[0].programmeId) : false
    //         return programExist
    //     }
    //     return true
    // }

    async getAllevents(accessLevel: number) {
        const eventCollRef = await firstValueFrom(this.eventService.getAllEvents());
        // const currUserUid: string = await this.userService.getUid();
        // const checkUserAccessLevel: any = await this.userService.getDocDataById(currUserUid);
        let eventArr
        if (Number(accessLevel) == 10) {
            eventArr = eventCollRef.map(event => ({
                id: event.docId,
                title: event.eventTitle,
                type: 'collapsable',
                icon: 'heroicons_outline:trophy',
                link: `events-workflow/${event.docId}`,
                children: event.batches.map(batch => ({
                    submissionsLength: batch.submissions.length,
                    id: batch.batchId,
                    title: batch.batchName,
                    type: 'basic',
                    queryParams: {
                        eventId: event.docId,
                        batchId: batch.batchId,
                    },
                    icon: 'mat_solid:label_important',
                    link: `events-config`,
                }))
            }))
        }

        if (Number(accessLevel) !== 10) {
            eventArr = eventCollRef
                .filter(event => event.batches.some(batch => batch.submissions.length > 0))
                .map(event => ({
                    id: event.docId,
                    title: event.eventTitle,
                    type: 'collapsable',
                    icon: 'heroicons_outline:trophy',
                    link: `events-workflow/${event.docId}`,
                    children: event.batches
                        .filter(batch => batch.submissions.length > 0)
                        .map(batch => ({
                            submissionsLength: batch.submissions.length,
                            id: batch.batchId,
                            title: batch.batchName,
                            type: 'basic',
                            queryParams: {
                                eventId: event.docId,
                                batchId: batch.batchId,
                            },
                            icon: 'mat_solid:label_important',
                            link: `events-config`,
                        })),
                }));
        }

        const navItemsTemp = [
            {
                id: 'contests',
                title: 'Events',
                type: 'group',
                icon: 'heroicons_outline:collection',
                children: eventArr
            },
        ]

        return eventArr?.length ? navItemsTemp : []
    }

    async getContestDocs(): Promise<FuseNavigationItem[]> {
        const contestCollRef = await firstValueFrom(this.contestService.getAllContest());
        this.submissionContestSubject$.next(contestCollRef)
        const contestArr: FuseNavigationItem[] = contestCollRef.map(contest => ({
            id: contest.docId,
            title: contest.contestTitle,
            type: 'collapsable',
            icon: 'heroicons_outline:trophy',
            link: `contests-workflow/${contest.docId}`,
            children: contest.stagesNames.map(stage => ({
                id: stage.stageId,
                title: stage.stageName,
                type: 'basic',
                queryParams: {
                    contestId: contest.docId,
                    stageId: stage.stageId,
                    // stageNum: stage.stageNumber,
                },
                // icon: 'heroicons_outline:academic-cap',
                icon: 'mat_solid:label_important',
                link: `contests-config`,
            }))
        }))

        const navItemsTemp: FuseNavigationItem[] = [
            {
                id: 'contests',
                title: 'Contests and Challenges',
                type: 'group',
                icon: 'heroicons_outline:collection',
                children: contestArr
            },

        ]

        return contestArr?.length ? navItemsTemp : []
    }

    nominationNav() {
        const contestCollRef = this.submissionContestSubject$.value
        let nominationArr: FuseNavigationItem[] = []
        contestCollRef?.forEach(contest => {
            let nArr = []
            contest.stagesNames.filter(stage => {

                if (stage?.isNominationAllowed) {
                    nArr.push({
                        id: stage.stageId,
                        title: stage.stageName,
                        type: 'basic',
                        queryParams: {
                            contestId: contest.docId,
                            stageId: stage.stageId,
                            // stageNum: stage.stageNumber,
                        },
                        // icon: 'heroicons_outline:academic-cap',
                        // icon: 'mat_solid:label_important',
                        icon: 'mat_solid:label_important',
                        link: `nomination-dashboard`,
                        disabled: this.checkDate(stage?.nominationStartDate)
                    })
                }
            })

            if (nArr.length) {
                nominationArr.push({
                    id: contest.docId,
                    title: contest.contestTitle,
                    type: 'collapsable',
                    // icon: 'heroicons_outline:trophy',
                    icon: 'feather:nomination',
                    // link: `contests-workflow/${contest.docId}`,
                    children: nArr
                })
            }
        })

        const navItemsTemp: FuseNavigationItem[] = [
            {
                id: 'nomination',
                title: 'Nominations',
                type: 'group',
                icon: 'heroicons_outline:collection',
                children: nominationArr
            },

        ]

        return nominationArr?.length ? navItemsTemp : []
    }

    getStemClubs(teacherClassrooms: Array<any>, userAccessLevel: number) {
        let stemClubsArray: any = Object?.values(teacherClassrooms || {}).filter((classroom: any) => classroom.hasOwnProperty('type') && classroom.type === 'STEM-CLUB');
        stemClubsArray = stemClubsArray.sort((a, b) => (b.institutionName.localeCompare(a.institutionName)));
        // Grouping objects by institutionId
        let groupedByInstitutionId = {};

        stemClubsArray.forEach(obj => {
            if (!groupedByInstitutionId[obj.institutionId]) {
                groupedByInstitutionId[obj.institutionId] = [];
            }
            groupedByInstitutionId[obj.institutionId].push(obj);
        });

        // Convert the grouped result
        for (let key in groupedByInstitutionId) {
            const stemClubsArr = [{
                id: key,
                title: groupedByInstitutionId[key][0].institutionName,
                type: 'collapsable',
                icon: 'heroicons_outline:trophy',
                // link: ``,
                children: groupedByInstitutionId[key].sort().map(stemClub => ({
                    id: stemClub.classroomId,
                    title: stemClub.stemClubName,
                    type: 'basic',
                    // icon: 'mat_solid:label_important',
                    icon: 'feather:classroom2',
                    active: false,
                    link: `dashboard/${stemClub.classroomId}`,
                    queryParams: { institutionId: stemClub.institutionId, classroomId: stemClub.classroomId, programmeId: stemClub.programmes[0].programmeId }
                }))
            }]

            const navItemsTemp = [
                {
                    id: 'stemclubs',
                    title: 'Stem Clubs',
                    type: 'group',
                    icon: 'heroicons_outline:collection',
                    children: stemClubsArr
                },
            ];

            return stemClubsArr?.length ? navItemsTemp : []
        }
    }

    // events() {
    //     const navItemsTemp: FuseNavigationItem[] = [
    //         {
    //             id: 'events',
    //             title: 'Event Doon',
    //             type: 'group',
    //             icon: 'mat_solid:event',
    //             children: [
    //                 {
    //                     id: 'doon',
    //                     title: 'Doon Workshop',
    //                     type: 'collapsable',
    //                     // icon: 'mat_solid:update',
    //                     icon: 'mat_solid:event',
    //                     children: [{
    //                         id: 'doonWorkshop1',
    //                         title: '1st & 2nd December',
    //                         type: 'basic',
    //                         icon: 'mat_solid:label_important',
    //                         link: 'events',
    //                         queryParams: {
    //                             eventId: 'RUP15rPmasOWdIEVRVJA',
    //                             batchId: '660ch',
    //                             submId: 'q1eEp8ePk1rLV8F6o3ir',
    //                             workflowId: 'NHEab2SPZe5DyKiiGXZu',
    //                         },
    //                     },
    //                     {
    //                         id: 'doonWorkshop2',
    //                         title: '4th December',
    //                         type: 'basic',
    //                         icon: 'mat_solid:label_important',
    //                         link: 'events',
    //                         queryParams: {
    //                             eventId: 'RUP15rPmasOWdIEVRVJA',
    //                             batchId: '661ch',
    //                             submId: '0SLGy4EzC3sPMZ8vIfBl',
    //                             workflowId: 'PyxgrhSPZe5DyKii4h7x',
    //                         },
    //                     }
    //                     ]
    //                 }
    //             ]
    //         },
    //     ]
    //     return navItemsTemp
    // }

    getAdminView(): FuseNavigationItem[] {
        const navItemsTemp: FuseNavigationItem[] = [
            {
                id: 'user-interface',
                title: 'Admin',
                type: 'group',
                icon: 'heroicons_outline:collection',
                children: [
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Set Up Wizard',
                        type: 'basic',
                        icon: 'mat_solid:settings',
                        link: '/manage'
                    },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Institutions',
                        type: 'basic',
                        // icon: 'mat_outline:app_registration',
                        icon: 'feather:institute2',
                        link: '/institutions-list'
                    },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Classrooms',
                        type: 'basic',
                        // icon: 'mat_outline:app_registration',
                        icon: 'feather:classroom2',
                        link: '/classrooms'
                    },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Programme',
                        type: 'basic',
                        icon: 'heroicons_outline:presentation-chart-bar',
                        link: '/programmes',
                    },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Programme Templates',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard',
                        link: '/programme-templates',
                    },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Learning Units',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-square-bar',
                        link: '/learning-units'
                    },
                    // {
                    //     id: 'user-interface.advanced-search',
                    //     title: 'Stem Clubs',
                    //     type: 'basic',
                    //     icon: 'heroicons_outline:chart-square-bar',
                    //     link: '/stem-clubs'
                    // },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Assignments',
                        type: 'basic',
                        icon: 'heroicons_outline:academic-cap',
                        link: '/assignments'
                    },
                    {
                        id: 'user-interface.advanced-search',
                        title: 'Contests and Challenges',
                        type: 'basic',
                        icon: 'heroicons_outline:trophy',
                        link: '/contests'
                    },
                    {
                        id: 'events',
                        title: 'Events',
                        type: 'basic',
                        icon: 'mat_solid:event',
                        link: '/events-admin'
                    },
                ],

            },
        ]
        return navItemsTemp
    }

    checkDate(startDateInSec) {
        const startDate = startDateInSec?.seconds
        const today = Date.now() / 1000
        if (today < startDate) {
            return true
        }
        return false
    }

}
