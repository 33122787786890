import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Timestamp, arrayUnion, serverTimestamp } from '@angular/fire//firestore';
import { AngularFirestore, CollectionReference, QueryFn } from '@angular/fire/compat/firestore';
import { User } from 'app/core/dbOperations/user/user.types';
import { BehaviorSubject, Observable, ReplaySubject, first, firstValueFrom, lastValueFrom, map, take, tap } from 'rxjs';
import { MasterFirestore } from './master.firestore';

@Injectable({
    providedIn: 'root'
})
export class MasterService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    learningList: ReplaySubject<User> = new ReplaySubject<User>(1);
    getAllLiveLerningUnitsSub = new BehaviorSubject(null);

    allLUListMaster = new BehaviorSubject(null);
    // collectionName = 'CopyOfMaster';
    collectionName = 'Master';

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private afs: AngularFirestore,
        private masterFirestore: MasterFirestore) {
    }

    /* Add LU into master Document */
    async addNewLU(luData) {
        const latestMasterDoc = await this.getLatestMasterDoc('LEARNINGUNIT');
        const masterDocId = latestMasterDoc.docId;
        if (latestMasterDoc?.tacNames?.length <= 2000) {
            await this.afs.collection(this.collectionName).doc(masterDocId).set(
                { 'tacNames': arrayUnion({ ...luData, creationDate: new Date() }) },
                { merge: true })
        } else {
            const collName = this.collectionName
            const docType = 'LEARNINGUNIT'
            const fieldName = 'tacNames'
            const docData = luData;
            await this.addNewMasterDoc(collName, docType, fieldName, docData)
        }
    }

    /* Add Institute into master doc */
    async addInstitution(insituteData) {
        const latestMasterDoc = await this.getLatestMasterDoc('INSTITUTE');
        const masterDocId = latestMasterDoc.docId;
        if (latestMasterDoc?.institutionNames?.length <= 2999) {
            await this.afs.collection(this.collectionName).doc(masterDocId).set(
                { 'institutionNames': arrayUnion({ ...insituteData, creationDate: new Date() }) },
                { merge: true })
        } else {
            const collName = this.collectionName
            const docType = 'INSTITUTE'
            const fieldName = 'institutionNames'
            const docData = insituteData;
            await this.addNewMasterDoc(collName, docType, fieldName, docData)
        }
    }

    /**
     * Add new classroom to master doc
     */
    async addNewClassroom(classroomDataData) {
        const latestMasterDoc = await this.getLatestMasterDoc('CLASSROOM');
        const masterDocId = latestMasterDoc.docId;
        if (latestMasterDoc?.classrooms?.length <= 800) {
            await this.afs.collection(this.collectionName).doc(masterDocId).set(
                { 'classrooms': arrayUnion({ ...classroomDataData, creationDate: new Date(), updatedAt: new Date() }) },
                { merge: true })
        }
        else {
            const collName = this.collectionName
            const docType = 'CLASSROOM'
            const fieldName = 'classrooms'
            const docData = classroomDataData;
            await this.addNewMasterDoc(collName, docType, fieldName, docData)
        }
    }

    /**
     * Add new Programme Template to master doc
     */
    async addNewProgrammeTemplate(programmeTemplateData) {
        const latestMasterDoc = await this.getLatestMasterDoc('PROGRAMME_TEMPLATES');
        const masterDocId = latestMasterDoc.docId;
        if (latestMasterDoc?.programmeTemplates?.length <= 800) {
            await this.afs.collection(this.collectionName).doc(masterDocId).set(
                { 'programmeTemplates': arrayUnion({ ...programmeTemplateData, creationDate: new Date(), updatedAt: new Date() }) },
                { merge: true })
            return masterDocId;
        }
        else {
            const collName = this.collectionName
            const docType = 'PROGRAMME_TEMPLATES'
            const fieldName = 'programmeTemplates'
            const docData = programmeTemplateData;
            const masterDocData = await this.addNewMasterDoc(collName, docType, fieldName, docData);
            return masterDocData;
        };
    }

    /* Get All LerningUnit Master Documents */
    getAllLU() {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('type', '==', 'LEARNINGUNIT').orderBy('creationDate', 'desc');
        return this.masterFirestore.collection$(query);
    }

    /* Get All Institutes Doc */
    getAllInstitutes() {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('type', '==', 'INSTITUTE').orderBy('creationDate', 'desc');
        return this.masterFirestore.collection$(query);
    }

    /* Get All Institutes Doc */
    getAllClassrooms() {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('type', '==', 'CLASSROOM').orderBy('creationDate', 'desc');
        return this.masterFirestore.collection$(query);
    }

    /* Get All Programme Templates Doc */
    getAllProgrammeTemplates() {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('type', '==', 'PROGRAMME_TEMPLATES').orderBy('creationDate', 'desc');
        return this.masterFirestore.collection$(query);
    }

    /* Get Programme Template by ID */
    getProgrammeTemplateById(masterDocId: string) {
        return this.masterFirestore.doc$(masterDocId);
    }

    /* Update LU Master Document */
    async updateMasterTACs(masterDocId, masterData) {
        await this.afs.collection(this.collectionName).doc(masterDocId).set(
            { 'tacNames': masterData, updatedAt: serverTimestamp() },
            { merge: true })
    }

    /**
     * Update  master doc Institute
     */
    async updateInstitutesMasterDoc(masterDocId, masterData) {
        await this.afs.collection(this.collectionName).doc(masterDocId).set(
            { 'institutionNames': masterData, updatedAt: serverTimestamp() },
            { merge: true })
    }

    /**
    * Update  Classroom master document
    */
    async updateClassroomsMasterDoc(masterDocId, masterData) {
        const value = { 'classrooms': masterData, updatedAt: serverTimestamp() }
        await this.afs.collection(this.collectionName).doc(masterDocId).set(value,{ merge: true })
    }

    /**
     * Update Programme Template master document
     */
    updateProgrammeTemplateMasterDoc(masterDocId, masterData) {
        const value = { 'programmeTemplates': masterData, updatedAt: serverTimestamp() }
        return this.afs.collection(this.collectionName).doc(masterDocId).set(value, { merge: true })
    }

    async getLatestMasterDoc(type) {
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('type', '==', type).orderBy('creationDate', 'desc').limit(1);
        const collRef = this.masterFirestore.collection$(query);
        const doc: any = await firstValueFrom(collRef)
        return doc[0]
    }

    async addNewMasterDoc(collName, docType, fieldName, docData) {
        const latestDocId = docData.docId as String;
        const lastDashIndex = latestDocId.lastIndexOf('_');
        const fileNumber = parseInt(latestDocId.substring(lastDashIndex + 1)) + 1;
        const docId = `classroom_master_${fileNumber.toString().padStart(2, '0')}`;
        // const docId = await this.masterFirestore.getRandomGeneratedId();
        return await this.afs.collection(collName).doc(docId).set(
            {
                [`${fieldName}`]: arrayUnion({ ...docData, creationDate: new Date() }),
                'creationDate': serverTimestamp(),
                'type': docType,
                'docId': docId
            })
    }

    /* Firebase Exceed Document size limit */
    // async handleExceedDocSize(type, fieldName, insituteData) {
    //     const latestMasterDocId = await this.getLatestMasterDoc(type);
    //     await this.afs.collection(this.collectionName).doc(latestMasterDocId).set(
    //         { 'institutionNames': arrayUnion({ ...insituteData, updatedAt: new Date() }) },
    //         { merge: true }).catch(async err => {
    //             const { code, name } = err;
    //             if (code == 'invalid-argument' && name == 'FirebaseError') {
    //                 await this.addNewMasterDoc('Test', 'INSTITUTE', 'institutionNames', insituteData)
    //             }
    //         })
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    getDocsById(id) {
        return this.masterFirestore.doc$(id).pipe(take(1),
            tap((luList) => {
                return luList
            }))
    }

    getAllLearningUnits() {
        // return this.masterFirestore.doc$('TACtivities').pipe(take(1)).subscribe(d => {
        //     return this.learningList.next(d)
        // })
        const query: QueryFn = (ref: CollectionReference) =>
            ref.where('type', '==', 'LEARNINGUNIT').orderBy('creationDate', 'desc');
        return this.masterFirestore.collection$(query);
    }

    get user$(): Observable<User> {
        this._user.subscribe(a => {
        })
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<any> {
        return this.masterFirestore.doc$('YDqVvM9njKe8h4rbbYbXElEKHBA3' + '/Student' + '/' + 'jMQ6DTULY4LGhmTwyZSi')
            // return this.userFirestore.doc$(this.authUser.uid + '/Student' + '/' + 'jMQ6DTULY4LGhmTwyZSi')
            .pipe(take(1),
                tap((user) => {
                    this._user.next(user);
                })
            )
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    getAllgames() {
        return this.masterFirestore.getDocDataByDocId('games')
    }

    async getClassroomFromMaster(id: string) {
        const masterClassrooms = await lastValueFrom(this.getAllClassrooms().pipe(first()));
        const classroomsWithId = masterClassrooms.map(x => x.classrooms).flat().filter(x => x.classroomId === id)[0];
        return classroomsWithId;
    }

    getAllProgrammeTemplatesFromMaster() {
        return this.masterFirestore.getWithGet('programme_template_master_01');
    }

}
