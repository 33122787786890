import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/dbOperations/user/user.types';
import { DomainService } from 'app/shared/domain.service';
import { BehaviorSubject, first, lastValueFrom, map, Observable, ReplaySubject, take, tap } from 'rxjs';
import { WorkflowsFirestore } from './workflows.firestore';
import { WorkFlowStep } from './workflows.types';
import { CollectionReference, QueryFn } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root'
})
export class WorkflowsService {
    private _workflows = new ReplaySubject(1);
    currentLearningUnitsName = new BehaviorSubject(null)

    workFlowSteps = new BehaviorSubject<WorkFlowStep>(null)

    currentWorkFlowId = new BehaviorSubject<string>(null)
    currentWorkFlowInfo = new BehaviorSubject<string>(null)

    constructor(private _httpClient: HttpClient, private workflowsFirestore: WorkflowsFirestore,
        private domainService: DomainService) {
    }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    getWorkflowDoc(id) {
        return this.workflowsFirestore.getDocDataByDocId(id)
    }

    getWorkflowDocByIdOnce(id: string) {
        return this.workflowsFirestore.getWithGet(id);
    }

    set workflows(value) {
        // Store the value
        this._workflows.next(value);
    }

    get workflows$(): Observable<any> {
        this._workflows.subscribe(a => {
        })

        return this._workflows.asObservable();
    }

    get(id): Observable<any> {
        return this.workflowsFirestore.doc$(id).pipe(
            tap((workflows) => this._workflows.next(workflows)))
    }

    addNewWorkFlowTemplate(workFlowTempate: any): string {
        const workFlowId = this.workflowsFirestore.getRandomGeneratedId();
        workFlowTempate = Object.assign(workFlowTempate, { workflowId: workFlowId, 'isLocalHost': this.domainService.isLocalHost() });
        this.workflowsFirestore.createWithId(workFlowTempate, workFlowId)
        return workFlowId
    }

    updateWorkFlowTemplate(workFlowTempate: any, oldWorkFlowId: string) {
        this.workflowsFirestore.update(workFlowTempate, oldWorkFlowId)
    }

    // update(workflows): Observable<any> {
    //     return this._httpClient.patch<User>('api/common/workflowss', { workflows }).pipe(
    //         map((response) => {
    //             this._workflows.next(response);
    //         })
    //     );
    // }

    update(value, id: string) {
        return this.workflowsFirestore.update(value, id)
    }

    delete(id: string) {
        return this.workflowsFirestore.delete(id)
    }

    generateCustomId() {
        return this.workflowsFirestore.getRandomGeneratedId()
    }

    parseWorkflowTemplate(workFlowInfo: any, luDetails: any, isSaved: boolean, boardGradeKeys: any) {
        let obj = {
            templateId: workFlowInfo?.templateId || '',
            workflowId: workFlowInfo?.workflowId || '', // added to save & display progress of tactivity
            templateName: workFlowInfo?.templateName || '',
            workflowSteps: [],
            totalSteps: 0,
        }
        const d = workFlowInfo.workflowSteps
        obj.totalSteps = workFlowInfo?.workflowSteps?.length;
        const maturityKeys: any = {};
        const board = boardGradeKeys?.board;
        const grade = `grade_${boardGradeKeys?.grade?.toString()?.padStart(2, '0')}`;
        switch (boardGradeKeys?.maturity) {
            case 'Silver':
                maturityKeys.video = { category: 'video', subCategory: 'tacQuickVideoUrl', isBoardGradeDependant: false };
                maturityKeys.guide = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.material = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.observation = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.tttPpts = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.teacherNotes = { category: '', subCategory: '', isBoardGradeDependant: false };
                break;

            case 'Gold':
                maturityKeys.video = { category: 'video', subCategory: 'tacVideoUrl', isBoardGradeDependant: false };
                maturityKeys.guide = { category: 'graphics', subCategory: 'tacGuideOnline', isBoardGradeDependant: false };
                maturityKeys.material = { category: 'tacDev', subCategory: 'materialList', isBoardGradeDependant: true };
                maturityKeys.observation = { category: 'tacDev', subCategory: 'observationWorksheetPdf', isBoardGradeDependant: true };
                maturityKeys.tttPpts = { category: '3S', subCategory: 'tttPpts', isBoardGradeDependant: true };
                maturityKeys.teacherNotes = { category: '3S', subCategory: 'teacherNotes', isBoardGradeDependant: true };

                break;

            case 'Diamond':
                maturityKeys.video = { category: 'video', subCategory: 'varVideoUrl', isBoardGradeDependant: false };
                maturityKeys.guide = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.material = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.observation = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.tttPpts = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.teacherNotes = { category: '', subCategory: '', isBoardGradeDependant: false };
                break;

            case 'Platinum':
                maturityKeys.video = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.guide = { category: 'graphics', subCategory: 'tacGuideDiksha', isBoardGradeDependant: false };
                maturityKeys.material = { category: 'tacDev', subCategory: 'materialListLinks', isBoardGradeDependant: false };
                maturityKeys.observation = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.tttPpts = { category: '', subCategory: '', isBoardGradeDependant: false };
                maturityKeys.teacherNotes = { category: '3S', subCategory: 'teacherNotes', isBoardGradeDependant: true };
                break;

            default:
                break;
        }
        // if(isSaved){
        for (let w in workFlowInfo.workflowSteps) {
            const updateResourcePathCategorySubCategory = (content: any) => {
                switch (content.contentType) {
                    case 'video':
                        const resourcePathVideo = (maturityKeys?.video?.isBoardGradeDependant ? luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.video?.category]?.[maturityKeys?.video?.link]?.[board]?.[grade] : luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.video?.category]?.[maturityKeys?.video?.subCategory]) || '';

                        return !resourcePathVideo.length ? { resourcePath: luDetails?.resources?.
                            videoUrl, contentCategory:typeof(content.contentCategory)=='undefined' || content.contentCategory==''?maturityKeys[content.contentType]['category']:content.contentCategory, contentSubCategory: typeof(content.contentSubCategory)=='undefined'|| content.contentSubCategory==''?maturityKeys[content.contentType]['subCategory']:content.contentSubCategory } : { resourcePath: resourcePathVideo, contentCategory: maturityKeys[content.contentType]['category'], contentSubCategory: maturityKeys[content.contentType]['subCategory'] };

                    case 'guide':
                        const resourcePathGuide = (maturityKeys?.guide?.isBoardGradeDependant ? luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.guide?.category]?.[maturityKeys?.guide?.subCategory]?.[board]?.[grade] : luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.guide?.category]?.[maturityKeys?.guide?.subCategory]) || '';
                        return !resourcePathGuide.length ? { resourcePath: luDetails?.resources?.guidePath, contentCategory: typeof (content.contentCategory)=='undefined'|| content.contentCategory==''?maturityKeys[content.contentType]['category']:content.contentCategory,
                             contentSubCategory:typeof (content.contentSubCategory)=='undefined'|| content.contentSubCategory==''?maturityKeys[content.contentType]['subCategory']:content.contentSubCategory } : { resourcePath: resourcePathGuide, contentCategory: maturityKeys[content.contentType]['category'], contentSubCategory: maturityKeys[content.contentType]['subCategory'] };

                    case 'material':
                        const resourcePathMaterial = (maturityKeys?.material?.isBoardGradeDependant ? luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.material?.category]?.[maturityKeys?.material?.subCategory]?.[board]?.[grade] : luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.material?.category]?.[maturityKeys?.material?.subCategory]) || '';
                        return !resourcePathMaterial.length ? { resourcePath: luDetails?.resources?.materialPath, contentCategory: typeof (content.contentCategory) == 'undefined' || content.contentCategory=='' ? maturityKeys[content.contentType]['category'] : content.contentCategory, contentSubCategory: typeof (content.contentSubCategory) == 'undefined' || content.contentSubCategory=='' ? maturityKeys[content.contentType]['subCategory'] : content.contentSubCategory } : { resourcePath: resourcePathMaterial, contentCategory: maturityKeys[content.contentType]['category'], contentSubCategory: maturityKeys[content.contentType]['subCategory'] };

                    case 'observation':
                        const resourcePathObservation = (maturityKeys?.observation?.isBoardGradeDependant ? luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.observation?.category]?.[maturityKeys?.observation?.subCategory]?.[board]?.[grade] : luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.observation?.category]?.[maturityKeys?.observation?.subCategory]) || '';
                        return !resourcePathObservation.length ? { resourcePath: luDetails?.resources?.observationPath, contentCategory: typeof (content?.contentCategory) == 'undefined'|| content.contentCategory=='' ? maturityKeys[content.contentType]['category'] : content.contentCategory, contentSubCategory: typeof (content?.contentSubCategory) == 'undefined' || content.contentSubCategory==''? maturityKeys[content.contentType]['subCategory'] : content.contentSubCategory } : { resourcePath: resourcePathObservation, contentCategory: maturityKeys[content.contentType]['category'], contentSubCategory: maturityKeys[content.contentType]['subCategory'] };

                    case 'tttPpts':
                        const resourcePathTttPpts = (maturityKeys?.tttPpts?.isBoardGradeDependant ? luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.tttPpts?.category]?.[maturityKeys?.tttPpts?.subCategory]?.[board]?.[grade] : luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.tttPpts?.category]?.[maturityKeys?.tttPpts?.subCategory]) || '';
                        return { resourcePath: resourcePathTttPpts, contentCategory: typeof (content?.contentCategory) == 'undefined' || content.contentCategory=='' ? maturityKeys[content.contentType]['category'] : content.contentCategory, contentSubCategory: typeof (content?.contentSubCategory) == 'undefined' || content.contentSubCategory=='' ? maturityKeys[content.contentType]['subCategory'] : maturityKeys[content.contentType]['subCategory'] };

                    case 'teacherNotes':
                        const resourcePathTeacherNotes = (maturityKeys?.teacherNotes?.isBoardGradeDependant ? luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.teacherNotes?.category]?.[maturityKeys?.teacherNotes?.subCategory]?.[board]?.[grade] : luDetails?.resources?.[boardGradeKeys.maturity.toLowerCase()]?.[maturityKeys?.teacherNotes?.category]?.[maturityKeys?.teacherNotes?.subCategory]) || '';
                        return { resourcePath: resourcePathTeacherNotes, contentCategory: typeof (content?.contentCategory) == 'undefined' || content.contentCategory=='' ? maturityKeys[content.contentType]['category'] : content.contentCategory, contentSubCategory: typeof (content?.contentSubCategory) == 'undefined' || content.contentSubCategory=='' ? maturityKeys[content.contentType]['subCategory'] : maturityKeys[content.contentType]['subCategory'] };

                    default:
                        return { resourcePath: content.resourcePath, contentCategory: content.contentCategory, contentSubCategory: content.contentSubCategory };
                };
            };
            if (d[w]?.workflowId == 'additionalResoures') {
                const addResourcesArr = luDetails?.additionalResources?.length ? luDetails?.additionalResources : [];
                let addResContentsArr = []
                for (let addRes of addResourcesArr) {
                    if (addRes?.resourcePath) {
                        let content = {
                            additionalResourceType: addRes.fileExtension == 'pdf' ? 'PDF' : addRes.fileExtension == 'ppt' || addRes.fileExtension == 'pptx' ? 'PPT' : 'LINK',
                            contentIsLocked: true,
                            contentName: addRes.title,
                            contentType: 'additional resources',
                            contentCategory: 'additional resources',
                            contentSubCategory: '',
                            resourcePath: addRes.resourcePath,
                        }
                        addResContentsArr.push(content)
                    }
                }
                d[w]['contents'] = addResContentsArr
            } else {
                d[w]['contents'].forEach((c: any) => {
                    c.resourcePath = updateResourcePathCategorySubCategory(c).resourcePath ?? "";

                    c.contentCategory = updateResourcePathCategorySubCategory(c).contentCategory ?? "";
                    c.contentSubCategory = updateResourcePathCategorySubCategory(c).contentSubCategory ?? "";

                });
            };
            obj.workflowSteps.push(d[w])
        }
        return obj
        // }
        // else{
        //     console.log(obj)
        //     return obj
        // }

    }

    parseWorkflow(workFlowInfo) {
        let obj: WorkFlowStep = {
            workflowId: workFlowInfo?.workflowId || '',
            selectedStepView: {
                workflowStepId: '',
                workflowStepName: '',
                workflowStepDescription: '',
                workflowStepDuration: 0,
            },
            workflowSteps: [],
            totalSteps: 0,

            // updatedAt: null,
            // featured: null,
            progress: {
                currentStep: null,
                completed: null,
            }
        }

        const d = workFlowInfo.workflowSteps
        obj.totalSteps = workFlowInfo?.workflowSteps?.length
        for (let w in workFlowInfo.workflowSteps) {
            obj.workflowSteps.push(d[w])

        }
        this.workFlowSteps.next(obj)
        return obj

    }

    async getWorkflowsWithAssignmentId(assignmentId: string) {
        const query: QueryFn = (ref: CollectionReference) => ref.where('docId', '!=', '');
        const workflow = await lastValueFrom(this.workflowsFirestore.collection$(query).pipe(first()));
        return workflow.filter(x => JSON.stringify(x).includes(assignmentId));
    }

    async deleteAssignmentFromWorkflow(assignmentId: string) {
        // get workflows with assignmentId
        const workFlowWithAssignmentId = await this.getWorkflowsWithAssignmentId(assignmentId);
        // get the length of contents with assignmentId within workflow steps
        workFlowWithAssignmentId.map(x => {
            const contentWithAssignmentId = x.workflowSteps.filter(y => JSON.stringify(y).includes(assignmentId))[0].contents;
            // if there are multiple contents with assignmentId, then remove contents with assignmentId, else remove the workflowSteps
            if (contentWithAssignmentId.length > 1) {
                const workflowWithAssignmentIdRemoved = { ...x, workflowSteps: x.workflowSteps.map(y => { return { ...y, contents: y.contents.filter(x => x.assignmentId !== assignmentId) } }).filter(y => { return y.contents.length > 0 }) };
                this.updateWorkFlowTemplate(workflowWithAssignmentIdRemoved, workflowWithAssignmentIdRemoved.docId);
            } else {
                const workflowWithAssignmentIdRemoved = { ...x, workflowSteps: x.workflowSteps.filter(y => !JSON.stringify(y).includes(assignmentId)).map((x, i) => { return { ...x, sequenceNumber: i + 1 } }) };
                this.updateWorkFlowTemplate(workflowWithAssignmentIdRemoved, workflowWithAssignmentIdRemoved.docId);
            }
        })
    }

    moveToTrash(workflow: any) {
        this.workflowsFirestore.trashDocument(workflow, workflow?.docId, '--trash--', 'DeletedWorkflows');
    }

}
