// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // /*
    // for jigyasa-project
    firebase: {
        apiKey: "AIzaSyBFY04QO2NnH3seQ0lOUC0q6nCUERE0TJY",
        authDomain: "jigyasa-e3fbb.firebaseapp.com",
        projectId: "jigyasa-e3fbb",
        storageBucket: "jigyasa-e3fbb.appspot.com",
        messagingSenderId: "554559730042",
        appId: "1:554559730042:web:e830fd280c66dd661266c3",
        measurementId: "G-JGE79JX4GJ"
      },
    cloudFunctionUrl: 'jigyasa-e3fbb',
    // */
   placesApiKey: 'AIzaSyBrQDqPJ8WWwbVQWYCRjMIMn97uGSq-WOg',
   gmapApiKey:'AIzaSyDR7N_nDEePrP3PHCZVztnmyaeJ2yFKhJc'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
